import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label, Radio } from 'flowbite-react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormInput } from '../Generic';
import {
  setDataForChatBot,
  setUpdatedDataFromChatBot,
} from '../../features/chatbot/chatbot';
import {
  PENSION_APPLICATION_FORM_STEPS,
  PREFERRED_COMMUNICATION_LANGUAGE_OPTIONS,
  ROUTE_PATH,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
} from '../../helper/constant';
import { STORAGE_KEYS, radioButtonTheme } from '../../helper';
import { updatePensionApplicationFormStep } from '../../helper/function';
import axiosInstance from '../../api/axiosInstance';

const Preferences = ({ tabHandler, tabsRef }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location?.pathname;

  const { screenName, updatedDataFromChatBot } = useSelector(
    (state) => state.chatbot
  );
  const { pensionApplicationFormData } = useSelector(
    (state) => state.pensionApplicationForm
  );

  const [formData, setFormData] = useState({
    preferred_language: PREFERRED_COMMUNICATION_LANGUAGE_OPTIONS.ENGLISH,
    email_subscription: false,
  });

  const getPreferencesDetail = async () => {
    try {
      const response = await axiosInstance.get('profile/preferences');
      if (response?.data?.data?.language) {
        setFormData({
          preferred_language: response?.data?.data?.language,
          email_subscription: Boolean(
            response?.data?.data?.subscription?.email
          ),
        });
      }
    } catch (error) {
      console.error('while fetching ', error);
    }
  };

  useEffect(() => {
    if (
      screenName ===
      SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PREFERENCES
    ) {
      dispatch(
        setDataForChatBot({
          sender: localStorage.getItem(STORAGE_KEYS.token),
          node: 'preffered',
          preffered: {
            language: formData?.preferred_language,
            subscription: formData?.email_subscription,
          },
        })
      );
    }
  }, [screenName, formData]);

  useEffect(() => {
    const changeScreen = async () => {
      if (
        screenName ===
          SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PREFERENCES &&
        (updatedDataFromChatBot?.want_submit ||
          updatedDataFromChatBot?.is_next ||
          updatedDataFromChatBot?.is_previous)
      ) {
        if (
          updatedDataFromChatBot?.want_submit &&
          updatedDataFromChatBot?.data?.language
        ) {
          try {
            setFormData({
              preferred_language: updatedDataFromChatBot?.data?.language,
              email_subscription: Boolean(
                updatedDataFromChatBot?.data?.subscription
              ),
            });
            await axiosInstance.put('update/profile/preferences', {
              language: updatedDataFromChatBot?.data?.language,
              subscription: {
                email: Boolean(updatedDataFromChatBot?.data?.subscription),
              },
            });
          } catch (error) {
            console.error('while updating preference data', error);
          }
        }
        if (updatedDataFromChatBot?.is_next) {
          const updatedPensionApplicationFormData = await axiosInstance.get(
            'retirement/form/get'
          );
          const lastUpdatedStep =
            updatedPensionApplicationFormData?.data?.data?.step;
          if (
            pathname === ROUTE_PATH.APPLICATION_FORM &&
            (pensionApplicationFormData?.step ===
              PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PREFERENCES ||
              lastUpdatedStep ===
                PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PREFERENCES)
          ) {
            await updatePensionApplicationFormStep({
              step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS,
            });
          }
          tabHandler(PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS);
        } else if (updatedDataFromChatBot?.is_previous) {
          tabsRef?.current?.setActiveTab(3);
        }
        if (
          !updatedDataFromChatBot?.is_next &&
          !updatedDataFromChatBot?.is_previous
        ) {
          dispatch(
            setUpdatedDataFromChatBot({
              text: updatedDataFromChatBot?.text,
              redable_text: updatedDataFromChatBot?.redable_text,
            })
          );
        } else {
          dispatch(setUpdatedDataFromChatBot({}));
        }
      }
    };
    changeScreen();
  }, [updatedDataFromChatBot]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PREFERENCES ||
      screenName === SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.MY_PROFILE
    ) {
      getPreferencesDetail();
    }
  }, [screenName]);

  return (
    <>
      <div className='mb-6 py-2 border-b border-bord-g10 flex justify-between items-center'>
        <h3 className='text-base font-semibold text-b10'>
          Personal Information
        </h3>
        {/* <img src="/assets/images/icons/edit.svg" alt="Edit" /> */}
      </div>
      <form>
        <div className='mb-6'>
          <span className='mb-2 block text-lsm font-normal text-b10'>
            Language (Preferred Communication)
          </span>
          <div className='flex gap-6 items-center'>
            <div className='flex items-center gap-3'>
              <Radio
                theme={radioButtonTheme}
                id='english'
                value={PREFERRED_COMMUNICATION_LANGUAGE_OPTIONS.ENGLISH}
                checked={
                  formData?.preferred_language ===
                  PREFERRED_COMMUNICATION_LANGUAGE_OPTIONS.ENGLISH
                }
                onChange={async (e) => {
                  if (e.target.checked) {
                    try {
                      setFormData({
                        ...formData,
                        preferred_language: e.target.value,
                      });
                      await axiosInstance.put('update/profile/preferences', {
                        language: e.target.value,
                        subscription: {
                          email: formData?.email_subscription,
                        },
                      });
                    } catch (error) {
                      console.error('while updating preference data', error);
                    }
                  }
                }}
              />
              <Label
                className='text-lsm font-normal text-b10'
                htmlFor='english'
              >
                English
              </Label>
            </div>
            <div className='flex items-center gap-3'>
              <Radio
                theme={radioButtonTheme}
                id='french'
                value={PREFERRED_COMMUNICATION_LANGUAGE_OPTIONS.FRENCH}
                checked={
                  formData?.preferred_language ===
                  PREFERRED_COMMUNICATION_LANGUAGE_OPTIONS.FRENCH
                }
                onChange={async (e) => {
                  if (e.target.checked) {
                    try {
                      setFormData({
                        ...formData,
                        preferred_language: e.target.value,
                      });
                      await axiosInstance.put('update/profile/preferences', {
                        language: e.target.value,
                        subscription: {
                          email: formData?.email_subscription,
                        },
                      });
                    } catch (error) {
                      console.error('while updating preference data', error);
                    }
                  }
                }}
              />
              <Label className='text-lsm font-normal text-b10' htmlFor='french'>
                French
              </Label>
            </div>
          </div>
        </div>
        <div>
          <span className='mb-2 block text-lsm font-normal text-b10'>
            Subscriptions
          </span>
          <div className='flex gap-6 items-center'>
            <div className='flex items-center gap-3'>
              <div>
                <FormInput
                  type='checkbox'
                  className={
                    '!p-0 rounded-none !w-[18px] !h-[18px] text-primary bg-white border-black focus:ring-0 focus:ring-transparent focus:ring-offset-0'
                  }
                  checked={formData?.email_subscription}
                  onChange={async (e) => {
                    try {
                      setFormData({
                        ...formData,
                        email_subscription: e.target.checked,
                      });
                      await axiosInstance.put('update/profile/preferences', {
                        language: formData?.preferred_language,
                        subscription: {
                          email: e.target.checked,
                        },
                      });
                    } catch (error) {
                      console.error('while updating preference data', error);
                    }
                  }}
                />
              </div>
              <Label
                className='text-lsm font-normal text-b10'
                htmlFor='emailMe'
              >
                Email Me
              </Label>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

Preferences.propTypes = {
  tabHandler: PropTypes.func,
  tabsRef: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
};

PropTypes.defaultProps = {
  tabHandler: () => {},
  tabsRef: null,
};

export default Preferences;
