import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs } from 'flowbite-react';
import { useSelector } from 'react-redux';
import useResizeHeight from '../../hooks/useResizeHeight';
import ChatbotInput from '../../components/ChatbotInput/ChatbotInput';
import { FormSubmit } from '../../components/Generic';
import ScenarioDetails from '../../components/CreateScenarioComponents/ScenarioDetails';
import EmploymentDetails from '../../components/CreateScenarioComponents/EmploymentDetails';
import LeaveDetails from '../../components/CreateScenarioComponents/LeaveDetails';
import PensionPackage from '../../components/CreateScenarioComponents/PensionPackage';
import axiosInstance from '../../api/axiosInstance';
import { toastMessage } from '../../helper/function';
import {
  MARITAL_STATUS_OPTIONS,
  FLAG_Y_N,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';
import { STORAGE_KEYS } from '../../helper';

const customTabTheme = {
  base: 'block',
  tablist: {
    base: 'hidden',
    tabitem: {},
  },
  tabitemcontainer: {
    base: 'p-0',
  },
  tabpanel: {
    base: 'p-0',
  },
};

const TABS = [{ title: '1' }, { title: '2' }, { title: '3' }];
const CreateScenario = () => {
  const { userInfo } = useSelector((state) => state.user);
  useResizeHeight('dashPanel');
  let { id } = useParams();
  const [isRenderTab, setIsRenderTab] = useState(false);
  const tabsRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const [scenarioData, setScenarioData] = useState({});

  const handleTabChange = (index) => {
    tabsRef.current?.setActiveTab(index);
    setActiveTab(index);
  };

  useEffect(() => {
    if (
      userInfo?.data &&
      userInfo?.data?.marital_status !== MARITAL_STATUS_OPTIONS.SINGLE
    ) {
      if (!TABS.some((tab) => tab.title === '4')) {
        TABS.push({ title: '4' });
        setIsRenderTab(true);
      } else {
        setIsRenderTab(true);
      }
    }
    if (userInfo?.data?.marital_status === MARITAL_STATUS_OPTIONS.SINGLE) {
      setIsRenderTab(true);
    }
  }, [userInfo?.data?.marital_status, isRenderTab]);

  useEffect(() => {
    if (id) {
      axiosInstance
        .get(`/scenarios/${id}`)
        .then((response) => {
          if (response?.data?.data?.id) {
            setScenarioData(response?.data?.data);
            localStorage.setItem(
              STORAGE_KEYS.selectedSceanrioId,
              response?.data?.data?.id
            );
            handleTabChange(
              response?.data?.data?.step === 4
                ? 0
                : response?.data?.data?.step || 0
            );
          }
        })
        .catch((error) => {
          toastMessage(
            TOAST_MESSAGE_TYPE.ERROR,
            error?.response?.data?.meta?.message || 'Something went wrong'
          );
        });
    }
  }, [id]);

  useEffect(() => {
    if (scenarioData?.id && !id) {
      axiosInstance
        .get(`/scenarios/${scenarioData?.id}`)
        .then((response) => {
          if (response?.data?.data?.id) {
            setScenarioData(response?.data?.data);
            localStorage.setItem(
              STORAGE_KEYS.selectedSceanrioId,
              response?.data?.data?.id
            );
          }
        })
        .catch((error) => {
          toastMessage(
            TOAST_MESSAGE_TYPE.ERROR,
            error?.response?.data?.meta?.message || 'Something went wrong'
          );
        });
    }
  }, [activeTab]);

  return (
    <div className='mt-4 flex gap-2'>
      <div className='w-1/3 flex flex-col flex-shrink-0 justify-between border-r border-r-bord-g10 min-h-full'>
        <div className='px-2 py-6'>
          {isRenderTab && (
            <div className='flex gap-10 w-3/5 justify-between relative after:h-px after:w-full after:bg-bord-g10 after:absolute after:top-1/2 after:-translate-y-1/2 after:z-0'>
              {TABS.map((tab, index) => (
                <button
                  key={`active-tab-${tab?.title}`}
                  className={`text-lsm font-normal w-8 h-8 border aspect-square rounded-full duration-300 z-1 ${
                    activeTab === index
                      ? 'bg-primary text-white'
                      : 'bg-light-gray text-neutral-gray border-bord-g10'
                  }`}
                  onClick={() => handleTabChange(index)}
                  disabled={
                    scenarioData?.is_calculated === FLAG_Y_N.YES
                      ? scenarioData?.step < index
                      : activeTab < index
                  }
                >
                  {tab.title}
                </button>
              ))}
            </div>
          )}
        </div>

        <div className='pt-2'>
          <span className='block text-xxs font-normal text-neutral-gray'>
            Suggestions:{' '}
          </span>

          <div className='py-4 flex gap-4'>
            <FormSubmit
              className='secondary-btn-blue'
              buttonText={'Back to Dashboard'}
            />
            <FormSubmit className='primary-btn' buttonText={'Yes I am ready'} />
          </div>

          <div className='mt-4 pr-2'>
            <ChatbotInput listen={true} sidebar={true} />
          </div>
          <span className='mt-4 block text-xxs font-normal text-neutral-gray'>
            *Piddy® can assist you with all of your pension needs. Just speak or
            type your request.
          </span>
        </div>
      </div>
      <div id='dashPanel' className='w-full p-4 relative overflow-auto'>
        <div className='pb-2'>
          <h2 className='text-xxl font-ibm font-semibold text-piddy-dark-blue'>
            Pension Calculator
          </h2>
        </div>
        <div className='relative px-4 py-4 h-[calc(100%_-_50px)] flex flex-col justify-between overflow-auto  rounded-2xl bg-white shadow-double'>
          <div className='h-[calc(100%_-_52px)] overflow-auto'>
            <Tabs
              theme={customTabTheme}
              ref={tabsRef}
              onActiveTabChange={(tab) => setActiveTab(tab)}
            >
              <Tabs.Item active>
                <ScenarioDetails
                  tabHandler={handleTabChange}
                  scenarioData={scenarioData}
                  setScenarioData={setScenarioData}
                />
              </Tabs.Item>
              <Tabs.Item>
                <EmploymentDetails
                  tabHandler={handleTabChange}
                  scenarioData={scenarioData}
                />
              </Tabs.Item>
              <Tabs.Item>
                <LeaveDetails
                  tabHandler={handleTabChange}
                  activeTab={activeTab}
                  scenarioData={scenarioData}
                />
              </Tabs.Item>
              <Tabs.Item>
                <PensionPackage
                  tabHandler={handleTabChange}
                  activeTab={activeTab}
                  scenarioData={scenarioData}
                />
              </Tabs.Item>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateScenario;
