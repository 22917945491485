import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormInput, FormSelect, FormSubmit } from '../Generic';
import axiosInstance from '../../api/axiosInstance';
import {
  STORAGE_KEYS,
  emailRegex,
  nameRegex,
  phoneRegex,
  zipCodeRegex,
} from '../../helper';
import {
  debounce,
  getExactValueFromEnum,
  toastMessage,
  updatePensionApplicationFormStep,
  validatePostalCode,
} from '../../helper/function';
import {
  CONTACT_NUMBER_TYPE_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  PENSION_APPLICATION_FORM_STEPS,
  ROUTE_PATH,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
  SELECT_CONTACT_NUMBER_TYPE_OPTIONS,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';
import {
  setDataForChatBot,
  setUpdatedDataFromChatBot,
  setWantToUpdateData,
} from '../../features/chatbot/chatbot';

function Contact({ tabsRef }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location?.pathname;

  const {
    screenName,
    updatedDataFromChatBot,
    wantToUpdateData,
    dataForChatBot,
  } = useSelector((state) => state.chatbot);
  const { pensionApplicationFormData } = useSelector(
    (state) => state.pensionApplicationForm
  );
  const { userInfo } = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [contactInfo, setContactInfo] = useState({});

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues: {
      contact_no: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      contact_no_type: CONTACT_NUMBER_TYPE_OPTIONS.CELL,
      email: '',
      zip_code: '',
      apartment: '',
    },
  });

  const formData = watch();

  const { call: debounceUpdate, cancel: cancelDebounce } = useCallback(
    debounce((formData) => {
      dispatch(
        setDataForChatBot({
          contact_information: {
            email: formData?.email,
            phone_number: formData?.contact_no,
            phone_number_type: formData?.contact_no_type,
            street: formData?.address_line_1,
            address_line_2: formData?.address_line_2,
            apartment: formData?.apartment,
            city: formData?.city,
            state: formData?.state,
            zip: formData?.zip_code,
          },
          node: 'contact_information',
          sender: localStorage.getItem(STORAGE_KEYS.token),
        })
      );
    }, 1000),
    []
  );

  const getContactInfo = async () => {
    try {
      const response = await axiosInstance('profile/contact-details');
      setContactInfo(response?.data?.data);
    } catch (err) {
      console.error('error while fetching personal details', err);
    }
  };

  const onSubmit = async (data, isNext = false, isPrevious = false) => {
    try {
      const formattedZipCode = validatePostalCode(data.zip_code);
      if (!formattedZipCode) {
        toastMessage(TOAST_MESSAGE_TYPE.ERROR, 'Please enter a valid ZIP code');
        return;
      }
      setIsLoading(true);
      const response = await axiosInstance.post(
        'update/profile/contact-details',
        {
          ...data,
          zip_code: formattedZipCode,
          contact_no_type: data.contact_no_type.value || data.contact_no_type,
        }
      );
      if (response?.data?.meta?.message) {
        toastMessage(TOAST_MESSAGE_TYPE.SUCCESS, response?.data?.meta?.message);
        if (isNext) {
          const updatedPensionApplicationFormData = await axiosInstance.get(
            'retirement/form/get'
          );
          const lastUpdatedStep =
            updatedPensionApplicationFormData?.data?.data?.step;
          if (
            pathname === ROUTE_PATH.APPLICATION_FORM &&
            (pensionApplicationFormData?.step ===
              PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_CONTACT ||
              lastUpdatedStep ===
                PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_CONTACT)
          ) {
            await updatePensionApplicationFormStep({
              step:
                userInfo?.marital_status === MARITAL_STATUS_OPTIONS.SINGLE
                  ? PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BENEFICIARIES
                  : PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SPOUSAL,
            });
          }
          tabsRef?.current?.setActiveTab(2);
        } else if (isPrevious) {
          tabsRef?.current?.setActiveTab(0);
        }
      }
      getContactInfo();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error('error while updating contact details', err);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        err?.response?.data?.meta?.message ||
          'Error while updating contact details'
      );
    }
  };

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_CONTACT ||
      screenName === SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.MY_PROFILE
    ) {
      getContactInfo();
    }
  }, [screenName]);

  useEffect(() => {
    if (contactInfo) {
      setValue('contact_no', contactInfo?.contact_no);
      setValue('address_line_1', contactInfo?.address_line_1);
      setValue('address_line_2', contactInfo?.address_line_2);
      setValue('city', contactInfo?.city);
      setValue('state', contactInfo?.state);
      setValue('contact_no_type', contactInfo?.contact_no_type);
      setValue('email', contactInfo?.email);
      setValue('zip_code', contactInfo?.zip_code);
      setValue('apartment', contactInfo?.apartment);
    }
  }, [contactInfo]);

  useEffect(() => {
    if (
      screenName ===
      SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_CONTACT
    ) {
      dispatch(
        setDataForChatBot({
          contact_information: {
            email: contactInfo?.email,
            phone_number: contactInfo?.contact_no,
            phone_number_type: contactInfo?.contact_no_type,
            street: contactInfo?.address_line_1,
            address_line_2: contactInfo?.address_line_2,
            apartment: contactInfo?.apartment,
            city: contactInfo?.city,
            state: contactInfo?.state,
            zip: contactInfo?.zip_code,
          },
          node: 'contact_information',
          sender: localStorage.getItem(STORAGE_KEYS.token),
        })
      );
    }
  }, [screenName, contactInfo]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_CONTACT &&
      wantToUpdateData &&
      !updatedDataFromChatBot?.is_error_occure &&
      !updatedDataFromChatBot?.want_submit
    ) {
      setValue('contact_no', updatedDataFromChatBot?.data?.phone_number);
      setValue('address_line_1', updatedDataFromChatBot?.data?.street);
      setValue('city', updatedDataFromChatBot?.data?.city);
      setValue('state', updatedDataFromChatBot?.data?.state);
      setValue('email', updatedDataFromChatBot?.data?.email);
      setValue('zip_code', updatedDataFromChatBot?.data?.zip);
      setValue('apartment', updatedDataFromChatBot?.data?.apartment);
      setValue(
        'contact_no_type',
        getExactValueFromEnum(
          updatedDataFromChatBot?.data?.phone_number_type,
          Object.values(CONTACT_NUMBER_TYPE_OPTIONS)
        ) || contactInfo?.contact_no_type
      );
      setValue('address_line_2', updatedDataFromChatBot?.data?.address_line_2);
      setContactInfo({
        ...contactInfo,
        contact_no: updatedDataFromChatBot?.data?.phone_number,
        address_line_1: updatedDataFromChatBot?.data?.street,
        city: updatedDataFromChatBot?.data?.city,
        state: updatedDataFromChatBot?.data?.state,
        email: updatedDataFromChatBot?.data?.email,
        zip_code: updatedDataFromChatBot?.data?.zip,
        apartment: updatedDataFromChatBot?.data?.apartment,
        contact_no_type:
          getExactValueFromEnum(
            updatedDataFromChatBot?.data?.phone_number_type,
            Object.values(CONTACT_NUMBER_TYPE_OPTIONS)
          ) || contactInfo?.contact_no_type,
        address_line_2: updatedDataFromChatBot?.data?.address_line_2,
      });
      dispatch(setWantToUpdateData(false));
    }
  }, [screenName, wantToUpdateData, updatedDataFromChatBot]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_CONTACT &&
      (updatedDataFromChatBot?.want_submit ||
        updatedDataFromChatBot?.is_next ||
        updatedDataFromChatBot?.is_previous) &&
      Object.values(dataForChatBot?.contact_information)?.length > 0
    ) {
      onSubmit(
        contactInfo,
        updatedDataFromChatBot?.is_next,
        updatedDataFromChatBot?.is_previous
      );
      if (
        !updatedDataFromChatBot?.is_next &&
        !updatedDataFromChatBot?.is_previous
      ) {
        dispatch(
          setUpdatedDataFromChatBot({
            text: updatedDataFromChatBot?.text,
            redable_text: updatedDataFromChatBot?.redable_text,
          })
        );
      } else {
        dispatch(setUpdatedDataFromChatBot({}));
      }
    }
  }, [updatedDataFromChatBot]);

  useEffect(() => {
    if (
      screenName ===
      SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_CONTACT
    ) {
      debounceUpdate(formData);
    }
    return () => {
      cancelDebounce();
    };
  }, [formData, debounceUpdate, screenName]);

  return (
    <>
      <div className='mb-6 py-2 border-b border-bord-g10 flex justify-between items-center'>
        <h3 className='text-base font-semibold text-b10'>
          Contact Information
        </h3>
        {/* <img src='/assets/images/icons/edit.svg' alt='Edit' /> */}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-2 gap-6'>
          <div className='flex flex-col gap-6'>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                Email address
              </span>
              <Controller
                name='email'
                control={control}
                rules={{
                  required: 'Email is required',
                  pattern: {
                    value: emailRegex,
                    message: 'Email is not valid.',
                  },
                }}
                render={({ field }) => (
                  <FormInput
                    type='email'
                    placeholder='alfonso.b@gmail.com'
                    icon={'/assets/images/icons/mail.svg'}
                    errorFieldName='email'
                    errors={errors}
                    {...field}
                  />
                )}
              />
            </div>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                Address Line
              </span>
              <div className='flex flex-col gap-4'>
                <Controller
                  name='address_line_1'
                  control={control}
                  rules={{
                    required: 'Address line 1 is required',
                  }}
                  render={({ field }) => (
                    <FormInput
                      type='text'
                      placeholder='223 Bay Street'
                      errorFieldName='address_line_1'
                      errors={errors}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name='address_line_2'
                  control={control}
                  render={({ field }) => (
                    <FormInput type='text' placeholder='Address 2' {...field} />
                  )}
                />
              </div>
            </div>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                Apartment / Unit
              </span>
              <Controller
                name='apartment'
                control={control}
                render={({ field }) => (
                  <FormInput
                    type='text'
                    placeholder='Apt'
                    errorFieldName='apartment'
                    errors={errors}
                    {...field}
                  />
                )}
              />
            </div>
            <div className='w-full flex gap-4'>
              <div className='flex-grow flex flex-col gap-4'>
                <span className='block text-lsm font-normal text-b10'>
                  City
                </span>
                <Controller
                  name='city'
                  control={control}
                  rules={{
                    required: 'City is required',
                    pattern: {
                      value: nameRegex,
                      message: 'City is not valid.',
                    },
                  }}
                  render={({ field }) => (
                    <FormInput
                      type='text'
                      placeholder='Los Angeles'
                      errorFieldName='city'
                      errors={errors}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className='flex-grow flex flex-col gap-4'>
                <span className='block text-lsm font-normal text-b10'>
                  State / Province
                </span>
                <Controller
                  name='state'
                  control={control}
                  rules={{
                    required: 'State is required',
                    pattern: {
                      value: nameRegex,
                      message: 'State is not valid.',
                    },
                  }}
                  render={({ field }) => (
                    <FormInput
                      type='text'
                      placeholder='CA'
                      errorFieldName='state'
                      errors={errors}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className='flex-grow flex flex-col gap-4'>
                <span className='block text-lsm font-normal text-b10'>
                  ZIP / Postal code
                </span>
                <Controller
                  name='zip_code'
                  control={control}
                  rules={{
                    required: 'Zip code is required',
                    pattern: {
                      value: zipCodeRegex,
                      message: 'Zip code is not valid.',
                    },
                  }}
                  render={({ field }) => (
                    <FormInput
                      type='text'
                      placeholder='90210'
                      errorFieldName='zip_code'
                      errors={errors}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className='flex flex-col gal-6'>
            <div className='flex flex-col gap-4'>
              <span className='block text-lsm font-normal text-b10'>
                Phone Numbers
              </span>
              <div className='flex gap-4'>
                <Controller
                  name='contact_no_type'
                  rules={{ required: 'Contact type is required' }}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormSelect
                        errors={errors}
                        id='countryCode'
                        SelectOptions={SELECT_CONTACT_NUMBER_TYPE_OPTIONS}
                        value={SELECT_CONTACT_NUMBER_TYPE_OPTIONS?.find(
                          (option) => option.value === value
                        )}
                        onChange={onChange}
                      />
                    );
                  }}
                />
                <div className='w-full'>
                  <Controller
                    name='contact_no'
                    control={control}
                    rules={{
                      required: 'Contact number is required',
                      pattern: {
                        value: phoneRegex,
                        message: 'Contact number is not valid',
                      },
                    }}
                    render={({ field }) => (
                      <FormInput
                        type='text'
                        placeholder='213-226-1132'
                        errorFieldName='contact_no'
                        errors={errors}
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex justify-end mt-6'>
          <FormSubmit
            type={'submit'}
            buttonText={'Save Changes'}
            btnDisable={isLoading}
          />
        </div>
      </form>
    </>
  );
}

Contact.propTypes = {
  tabsRef: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
};

PropTypes.defaultProps = {
  tabsRef: null,
};

export default Contact;
