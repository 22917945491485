import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ScenarioCard from '../../components/PensionCalculator/ScenarioCard';
import { scenarioPropType, STORAGE_KEYS } from '../../helper';
import { FormSubmit } from '../../components/Generic';
import ConfirmationModal from '../../components/Generic/ConfirmationModal';
import {
  ROUTE_PATH,
  FLAG_Y_N,
  TOAST_MESSAGE_TYPE,
} from '../../helper/constant';
import { toastMessage } from '../../helper/function';

function ScenarioSection({
  scenariosData,
  isScenarioSelected,
  selectedScenario,
  setSelectedScenario,
  isRadioBtn,
  isCheckbox,
  compareScenarioIds,
  setCompareScenarioIds,
  fetchScenarios,
  handleDeleteAllScenarios,
  projectionData,
}) {
  const [wantToDeleteScenario, setWantToDeleteScenario] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const updateUrl = (ids) => {
    if (ids.length === 0) {
      navigate(ROUTE_PATH.COMPARE_SCENARIO);
    } else {
      const newUrl = `${ROUTE_PATH.COMPARE_SCENARIO}?ids=${ids.join(',')}`;
      navigate(newUrl);
    }
  };

  const handleChange = (scenario, isChecked) => {
    let currentIds = compareScenarioIds.map((s) => s.scenarioId.toString());

    if (isChecked) {
      if (
        scenario.id !== projectionData.id &&
        scenario.is_calculated !== FLAG_Y_N.YES
      ) {
        toastMessage(
          TOAST_MESSAGE_TYPE.ERROR,
          'This scenario is not calculated yet. Please calculate it first.'
        );
        return;
      }
      if (!currentIds.includes(scenario.id.toString())) {
        currentIds.push(scenario.id.toString());
      }
    } else {
      currentIds = currentIds.filter((id) => id !== scenario.id.toString());
    }

    // Ensure we always have at least 2 item selected
    if (currentIds.length < 2) {
      toastMessage(
        TOAST_MESSAGE_TYPE.INFO,
        'At least one item must be selected for comparison.'
      );
      return;
    }

    setCompareScenarioIds(
      currentIds.map((id) => {
        if (id === projectionData.id.toString()) {
          return { title: projectionData.name, scenarioId: projectionData.id };
        }
        const scenarioItem = scenariosData.find((s) => s.id.toString() === id);
        return { title: scenarioItem.name, scenarioId: scenarioItem.id };
      })
    );

    updateUrl(currentIds);
  };

  return (
    <div className='mt-4 p-3 border border-black rounded-2xl bg-light-gray'>
      <div className='mb-4 pb-4 border-b border-b-bord-g10 flex justify-between gap-6'>
        <div className='flex gap-6'>
          <Link
            to={scenariosData?.length >= 4 ? '' : ROUTE_PATH.CREATE_SCENARIO}
            className='rounded-80x border border-black py-2.5 px-6 flex items-center gap-3 duration-500 group hover:bg-black '
          >
            <span className='text-xxs font-medium text-b10 duration-500 group-hover:text-white'>
              Create New Scenario
            </span>
            <img
              className='duration-500 group-hover:invert '
              src='/assets/images/icons/plus-circle.svg'
              alt='Create'
            />
          </Link>
          <Link
            to={
              scenariosData?.length > 0 &&
              scenariosData?.filter(
                (data) => data.is_calculated === FLAG_Y_N.YES
              )?.length > 0 &&
              location.pathname === ROUTE_PATH.PENSION_CALCULATOR
                ? ROUTE_PATH.COMPARE_SCENARIO
                : location.pathname === ROUTE_PATH.COMPARE_SCENARIO
                ? ROUTE_PATH.PENSION_CALCULATOR
                : ''
            }
            className={`rounded-80x border border-black py-2.5 px-6 flex items-center gap-3 duration-500 group ${
              location.pathname === ROUTE_PATH.COMPARE_SCENARIO
                ? 'bg-black hover:bg-white'
                : 'hover:bg-black'
            } `}
          >
            <span
              className={`text-xxs font-medium text-b10 duration-500 ${
                location.pathname === ROUTE_PATH.COMPARE_SCENARIO
                  ? 'text-white group-hover:text-black'
                  : 'hover:text-white'
              }`}
            >
              Compare Scenarios
            </span>
            <img
              className={`duration-500 ${
                location.pathname === ROUTE_PATH.COMPARE_SCENARIO
                  ? 'invert group-hover:filter-none'
                  : ' group-hover:invert'
              }`}
              src='/assets/images/icons/bar-chart.svg'
              alt='Compare'
            />
          </Link>
        </div>
        {location.pathname !== ROUTE_PATH.COMPARE_SCENARIO && (
          <FormSubmit
            buttonText='Delete All'
            className='border-black duration-500 secondary-btn-white text-black hover:text-white hover:bg-black'
            onClick={() => setWantToDeleteScenario(true)}
            btnDisable={!scenariosData?.length}
          />
        )}
      </div>
      <ConfirmationModal
        open={wantToDeleteScenario}
        text='Are you sure you want to delete all scenarios?'
        handleClose={() => setWantToDeleteScenario(false)}
        handleSubmit={() => {
          handleDeleteAllScenarios();
          setWantToDeleteScenario(false);
        }}
      />
      <div className='flex gap-6'>
        {isRadioBtn && (
          <>
            <div
              className={`p-2 flex flex-col gap-4 items-center rounded-2xl border-2 ${
                !isScenarioSelected
                  ? 'border-tertiary-gray'
                  : 'border-transparent'
              }`}
            >
              <label className='flex flex-col gap-4 bg-primary border border-bord-g10 px-4 py-2 rounded-xl shadow-double min-w-[300px]'>
                <div className='pb-2 border-b border-b-neutral-gray flex justify-between items-center w-full'>
                  <p className='text-base font-semibold text-b10'>
                    My Projection
                  </p>
                  <img src='/assets/images/icons/info-circle.svg' alt='Info' />
                </div>

                <div className='flex gap-2 justify-evenly'>
                  <div className='flex flex-col '>
                    <p className='text-xxs font-normal text-neutral-gray'>
                      Annual
                    </p>
                    <p className='text-base font-medium text-b10'>
                      {projectionData?.net?.annual_pension || '- -'}
                    </p>
                  </div>
                  <span className='block w-px min-h-full bg-white bg-opacity-40'></span>
                  <div className='flex flex-col '>
                    <p className='text-xxs font-normal text-neutral-gray'>
                      Monthly
                    </p>
                    <p className='text-base font-medium text-b10'>
                      {projectionData?.net?.monthly_pension || '- -'}
                    </p>
                  </div>
                </div>

                <input
                  type='checkbox'
                  className='w-[18px] h-[18px] text-primary bg-white opacity-0 border-black focus:ring-0 focus:ring-transparent focus:ring-offset-0 cursor-pointer absolute'
                  checked={!isScenarioSelected}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedScenario(null);
                      localStorage.removeItem(STORAGE_KEYS.selectedSceanrioId);
                    }
                  }}
                />
              </label>
            </div>
            <span className='block w-px min-h-full bg-bord-g10'></span>
          </>
        )}
        {isCheckbox && (
          <>
            <div className='p-2 flex flex-col gap-4 items-center shrink-0'>
              <div
                className='flex flex-col gap-4 border border-bord-g10 px-4 py-2 rounded-xl shadow-double min-w-[300px]'
                style={{ backgroundColor: projectionData?.color || '#FFFFFF' }}
              >
                <div className='pb-2 border-b border-b-neutral-gray flex  justify-between items-center w-full'>
                  <p className='text-base font-semibold text-b10'>
                    {projectionData?.name}
                  </p>
                  <img src='/assets/images/icons/info-circle.svg' alt='Info' />
                </div>

                <div className='flex gap-2 justify-evenly'>
                  <div className='flex flex-col '>
                    <p className='text-xxs font-normal text-neutral-gray'>
                      Annual
                    </p>
                    <p className='text-base font-medium text-b10'>
                      {projectionData?.net?.annual_pension || '- -'}
                    </p>
                  </div>
                  <span className='block w-px min-h-full bg-white bg-opacity-40'></span>
                  <div className='flex flex-col '>
                    <p className='text-xxs font-normal text-neutral-gray'>
                      Monthly
                    </p>
                    <p className='text-base font-medium text-b10'>
                      {projectionData?.net?.monthly_pension || '- -'}
                    </p>
                  </div>
                </div>
              </div>
              <div className='w-fit'>
                {isCheckbox && (
                  <input
                    type='checkbox'
                    className='w-[18px] h-[18px] text-primary bg-white border-black focus:ring-0 focus:ring-transparent focus:ring-offset-0'
                    checked={compareScenarioIds?.some(
                      (item) => item?.scenarioId === projectionData?.id
                    )}
                    onChange={(e) =>
                      handleChange(projectionData, e.target.checked)
                    }
                  />
                )}
              </div>
            </div>

            <span className='block w-px min-h-full bg-bord-g10'></span>
          </>
        )}

        <div className=' flex gap-6 overflow-auto'>
          <ScenarioCard
            scenariosData={scenariosData}
            isScenarioSelected={isScenarioSelected}
            selectedScenario={selectedScenario}
            setSelectedScenario={setSelectedScenario}
            isRadioBtn={isRadioBtn}
            isCheckbox={isCheckbox}
            compareScenarioIds={compareScenarioIds}
            setCompareScenarioIds={setCompareScenarioIds}
            fetchScenarios={fetchScenarios}
            projectionData={projectionData}
          />
        </div>
      </div>
    </div>
  );
}

ScenarioSection.propTypes = {
  isScenarioSelected: PropTypes.bool,
  scenariosData: PropTypes.arrayOf(scenarioPropType),
  selectedScenario: scenarioPropType,
  setSelectedScenario: PropTypes.func,
  isRadioBtn: PropTypes.bool,
  isCheckbox: PropTypes.bool,
  compareScenarioIds: PropTypes.array,
  setCompareScenarioIds: PropTypes.func,
  fetchScenarios: PropTypes.func,
  handleDeleteAllScenarios: PropTypes.func,
  projectionData: scenarioPropType,
};

ScenarioSection.defaultProps = {
  isScenarioSelected: false,
  scenariosData: [],
  selectedScenario: null,
  setSelectedScenario: () => {},
  isRadioBtn: false,
  isCheckbox: false,
  compareScenarioIds: [],
  setCompareScenarioIds: () => {},
  fetchScenarios: () => {},
  handleDeleteAllScenarios: () => {},
};

export default ScenarioSection;
