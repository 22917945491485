import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactSignatureCanvas from 'react-signature-canvas';
// import { useDropzone } from 'react-dropzone';
import { Controller, useForm } from 'react-hook-form';
import { Label, Radio } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';
import { FormInput, FormSubmit } from '../Generic';
import {
  nameRegex,
  radioButtonTheme,
  STORAGE_KEYS,
  zipCodeRegex,
} from '../../helper';
import {
  PENSION_APPLICATION_FORM_STEPS,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
  TOAST_MESSAGE_TYPE,
  TYPE_OF_ACCOUNT_OPTIONS,
} from '../../helper/constant';
import {
  base64ToFile,
  getExactValueFromEnum,
  getFullName,
  toastMessage,
  updatePensionApplicationFormStep,
  validatePostalCode,
} from '../../helper/function';
import axiosInstance from '../../api/axiosInstance';
import {
  setDataForChatBot,
  setUpdatedDataFromChatBot,
  setWantToUpdateData,
} from '../../features/chatbot/chatbot';

const BankingDirectDeposit = ({ tabHandler }) => {
  const sigPad = useRef(null);
  const dispatch = useDispatch();

  const { pensionApplicationFormData } = useSelector(
    (state) => state.pensionApplicationForm
  );
  const {
    screenName,
    wantToUpdateData,
    updatedDataFromChatBot,
    dataForChatBot,
  } = useSelector((state) => state.chatbot);

  const [trimmedDataURL, setTrimmedDataURL] = useState(null);
  const [disableApply, setDisableApply] = useState(false);
  // const [allFiles, setAllFiles] = useState([]);
  const [bankingDirectDepositDetail, setBankingDirectDepositDetail] = useState(
    {}
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    register,
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: {
      name: '',
      address_line_1: '',
      address_line_2: '',
      appartement: '',
      city: '',
      state: '',
      postal_code: '',
      bank_name: '',
      account_number: '',
      routing_number: '',
      type_of_account: TYPE_OF_ACCOUNT_OPTIONS.CHECKING,
    },
  });

  const formData = watch();

  // const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
  //   onDrop: (acceptedFiles) => {
  //     setAllFiles((prev) => [...prev, ...acceptedFiles]);
  //   },
  // });

  const clear = (e) => {
    e.preventDefault();
    sigPad.current.clear();
    setTrimmedDataURL(null);
    clearErrors('signature');
    setValue('signature', '');
  };

  const trim = (e) => {
    e.preventDefault();
    if (sigPad.current.isEmpty()) {
      setDisableApply(true);
    } else {
      setDisableApply(false);
      setTrimmedDataURL(
        sigPad.current.getTrimmedCanvas().toDataURL('image/png')
      );
    }
  };

  const getBankingDirectDepositDetail = async () => {
    try {
      const response = await axiosInstance.get(
        `retirement/form/${pensionApplicationFormData?.id}/bank-details`
      );
      setBankingDirectDepositDetail(response?.data?.data);
      setTrimmedDataURL(response?.data?.data?.employee_signature);
    } catch (err) {
      console.error('error while fetching banking direct deposit detail', err);
    }
  };

  const handleAutoFillPersonalInfo = async () => {
    try {
      const response = await axiosInstance.get('get-profile');
      if (response?.data?.data?.id) {
        setValue(
          'name',
          getFullName(
            response?.data?.data?.first_name,
            response?.data?.data?.middle_name,
            response?.data?.data?.last_name
          )
        );
        setValue(
          'address_line_1',
          response?.data?.data?.contact_information?.address_line_1
        );
        setValue(
          'address_line_2',
          response?.data?.data?.contact_information?.address_line_2
        );
        setValue(
          'appartement',
          response?.data?.data?.contact_information?.apartment
        );
        setValue('city', response?.data?.data?.contact_information?.city);
        setValue('state', response?.data?.data?.contact_information?.state);
        setValue(
          'postal_code',
          response?.data?.data?.contact_information?.zip_code
        );
        dispatch(
          setDataForChatBot({
            sender: localStorage.getItem(STORAGE_KEYS.token),
            banking_auto_fill: false,
            node: 'bank_direct_deposit',
            bank_direct_deposit: {
              bank_name: bankingDirectDepositDetail?.bank_name || '',
              account_number: bankingDirectDepositDetail?.account_number || '',
              bank_routing: bankingDirectDepositDetail?.routing_number || '',
              type_of_account:
                bankingDirectDepositDetail?.type_of_account ||
                TYPE_OF_ACCOUNT_OPTIONS.CHECKING,
              auth_full_name:
                getFullName(
                  response?.data?.data?.first_name,
                  response?.data?.data?.middle_name,
                  response?.data?.data?.last_name
                ) || '',
              street:
                response?.data?.data?.contact_information?.address_line_1 || '',
              address_line_2:
                response?.data?.data?.contact_information?.address_line_2 || '',
              apartment:
                response?.data?.data?.contact_information?.apartment || '',
              city: response?.data?.data?.contact_information?.city || '',
              state: response?.data?.data?.contact_information?.state || '',
              zip: response?.data?.data?.contact_information?.zip_code || '',
            },
          })
        );
      }
    } catch (error) {
      console.error('Error while auto filling personal info', error);
    }
  };

  const onSubmit = async (data, isNext = false, isPrevious = false) => {
    try {
      if (!trimmedDataURL) {
        toastMessage(
          TOAST_MESSAGE_TYPE.ERROR,
          'Please provide your signature.'
        );
        return;
      }
      const filename = 'employee_signature.png';
      const mimeType = 'image/png';
      const formData = new FormData();
      if (!trimmedDataURL.startsWith('http://') && !trimmedDataURL.startsWith('https://')) {
        const fileObject = base64ToFile(trimmedDataURL, filename, mimeType);
        formData.append('employee_signature', fileObject);
      }
      if (data.signature) {
        delete data.signature;
      }
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      const formattedZipCode = validatePostalCode(data.postal_code);
      if (!formattedZipCode) {
        toastMessage(TOAST_MESSAGE_TYPE.ERROR, 'Please enter a valid ZIP code');
        return;
      }
      formData.append('postal_code', formattedZipCode);

      const response = await axiosInstance.post(
        `retirement/form/${pensionApplicationFormData?.id}/bank-details/update`,
        formData
      );
      if (isPrevious) {
        tabHandler(
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_CENTER
        );
      } else if (response?.data?.data?.id || isNext) {
        if (
          pensionApplicationFormData?.step ===
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT
        ) {
          await updatePensionApplicationFormStep({
            step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REVIEW,
          });
        }
        tabHandler(PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REVIEW);
      }
    } catch (error) {
      console.error('Error submitting banking direct deposit form', error);
      toastMessage(
        TOAST_MESSAGE_TYPE.ERROR,
        error?.response?.data?.meta?.message || 'Something went wrong'
      );
    }
  };

  useEffect(() => {
    if (
      screenName ===
      SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT
    ) {
      getBankingDirectDepositDetail();
    }
  }, [screenName]);

  useEffect(() => {
    setValue('name', bankingDirectDepositDetail?.name);
    setValue('address_line_1', bankingDirectDepositDetail?.address_line_1);
    setValue('address_line_2', bankingDirectDepositDetail?.address_line_2);
    setValue('appartement', bankingDirectDepositDetail?.appartement);
    setValue('city', bankingDirectDepositDetail?.city);
    setValue('state', bankingDirectDepositDetail?.state);
    setValue('postal_code', bankingDirectDepositDetail?.postal_code);
    setValue('bank_name', bankingDirectDepositDetail?.bank_name);
    setValue('account_number', bankingDirectDepositDetail?.account_number);
    setValue('routing_number', bankingDirectDepositDetail?.routing_number);
    setValue(
      'type_of_account',
      bankingDirectDepositDetail?.type_of_account ||
        TYPE_OF_ACCOUNT_OPTIONS.CHECKING
    );
  }, [bankingDirectDepositDetail]);

  useEffect(() => {
    if (
      screenName ===
      SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT
    ) {
      dispatch(
        setDataForChatBot({
          sender: localStorage.getItem(STORAGE_KEYS.token),
          banking_auto_fill: bankingDirectDepositDetail?.name ? false : true,
          node: 'bank_direct_deposit',
          bank_direct_deposit: {
            bank_name: bankingDirectDepositDetail?.bank_name || '',
            account_number: bankingDirectDepositDetail?.account_number || '',
            bank_routing: bankingDirectDepositDetail?.routing_number || '',
            type_of_account:
              bankingDirectDepositDetail?.type_of_account ||
              TYPE_OF_ACCOUNT_OPTIONS.CHECKING,
            auth_full_name: bankingDirectDepositDetail?.name || '',
            street: bankingDirectDepositDetail?.address_line_1 || '',
            address_line_2: bankingDirectDepositDetail?.address_line_2 || '',
            apartment: bankingDirectDepositDetail?.appartement || '',
            city: bankingDirectDepositDetail?.city || '',
            state: bankingDirectDepositDetail?.state || '',
            zip: bankingDirectDepositDetail?.postal_code || '',
          },
        })
      );
    }
  }, [screenName, bankingDirectDepositDetail]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT &&
      wantToUpdateData &&
      !updatedDataFromChatBot?.is_error_occure &&
      !updatedDataFromChatBot?.want_submit
    ) {
      if (updatedDataFromChatBot?.data?.is_auto_fill_form) {
        handleAutoFillPersonalInfo();
        return;
      }
      setValue('name', updatedDataFromChatBot?.data?.data?.auth_full_name);
      setValue('address_line_1', updatedDataFromChatBot?.data?.data?.street);
      setValue(
        'address_line_2',
        updatedDataFromChatBot?.data?.data?.address_line_2 || ''
      );
      setValue(
        'appartement',
        updatedDataFromChatBot?.data?.data?.apartment || ''
      );
      setValue('city', updatedDataFromChatBot?.data?.data?.city);
      setValue('state', updatedDataFromChatBot?.data?.data?.state);
      setValue('postal_code', updatedDataFromChatBot?.data?.data?.zip);
      setValue('bank_name', updatedDataFromChatBot?.data?.data?.bank_name);
      setValue(
        'account_number',
        updatedDataFromChatBot?.data?.data?.account_number
      );
      setValue(
        'routing_number',
        updatedDataFromChatBot?.data?.data?.bank_routing
      );
      setValue(
        'type_of_account',
        getExactValueFromEnum(
          updatedDataFromChatBot?.data?.data?.type_of_account,
          Object.values(TYPE_OF_ACCOUNT_OPTIONS)
        ) || bankingDirectDepositDetail?.type_of_account
      );

      setBankingDirectDepositDetail({
        ...bankingDirectDepositDetail,
        name: updatedDataFromChatBot?.data?.data?.auth_full_name,
        address_line_1: updatedDataFromChatBot?.data?.data?.street,
        address_line_2:
          updatedDataFromChatBot?.data?.data?.address_line_2 || '',
        appartement: updatedDataFromChatBot?.data?.data?.apartment || '',
        city: updatedDataFromChatBot?.data?.data?.city,
        state: updatedDataFromChatBot?.data?.data?.state,
        postal_code: updatedDataFromChatBot?.data?.data?.zip,
        bank_name: updatedDataFromChatBot?.data?.data?.bank_name,
        account_number: updatedDataFromChatBot?.data?.data?.account_number,
        routing_number: updatedDataFromChatBot?.data?.data?.bank_routing,
        type_of_account:
          getExactValueFromEnum(
            updatedDataFromChatBot?.data?.data?.type_of_account,
            Object.values(TYPE_OF_ACCOUNT_OPTIONS)
          ) || bankingDirectDepositDetail?.type_of_account,
      });
      dispatch(setWantToUpdateData(false));
    }
  }, [screenName, wantToUpdateData, updatedDataFromChatBot]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT &&
      (updatedDataFromChatBot?.want_submit ||
        updatedDataFromChatBot?.is_next ||
        updatedDataFromChatBot?.is_previous) &&
      Object.values(dataForChatBot?.bank_direct_deposit)?.length > 0
    ) {
      onSubmit(
        bankingDirectDepositDetail,
        updatedDataFromChatBot?.is_next,
        updatedDataFromChatBot?.is_previous
      );
      if (
        !updatedDataFromChatBot?.is_next &&
        !updatedDataFromChatBot?.is_previous
      ) {
        dispatch(
          setUpdatedDataFromChatBot({
            text: updatedDataFromChatBot?.text,
            redable_text: updatedDataFromChatBot?.redable_text,
          })
        );
      } else {
        dispatch(setUpdatedDataFromChatBot({}));
      }
    }
  }, [updatedDataFromChatBot]);

  // const files = allFiles.map((file, index) => {
  //   return (
  //     <div className="flex items-center gap-2" key={file.path}>
  //       <div className="p-2.5 w-fit bg-light-gray rounded-lmd flex items-center gap-2">
  //         <img src="/assets/images/icons/file.svg" alt="File" />
  //         <p className="text-lsm font-normal text-b10">{file.path}</p>
  //       </div>

  //       <button
  //         className="text-black text-lsm font-bold"
  //         onClick={() => {
  //           setAllFiles(allFiles.filter((_, i) => i !== index));
  //         }}
  //       >
  //         <img src="/assets/images/icons/close.svg" alt="Cancel" />
  //       </button>
  //     </div>
  //   );
  // }, []);
  const saveSignature = () => {
    if (sigPad.current.isEmpty()) {
      setValue('signature', '');
    } else {
      setValue('signature', sigPad.current.getTrimmedCanvas().toDataURL());
    }
  };

  return (
    <>
      <div className='relative  h-full flex flex-col justify-between'>
        <div className='pb-2 sticky top-0  bg-light-gray'>
          <h2 className='text-xxl font-ibm font-semibold text-piddy-dark-blue'>
            Banking Direct Deposit
          </h2>
        </div>

        <div className='h-[calc(100%_-_50px)] overflow-auto'>
          <div className='p-4 rounded-2xl bg-white shadow-double flex flex-col gap-10'>
            <div className=' pb-2  border-b border-bord-g10 flex justify-between items-center'>
              <h3 className='text-base font-semibold text-b10'>
                Direct Deposit Authorization Form
              </h3>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='flex flex-col gap-6 '>
                <p className='text-lsm font-normal text-b10'>
                  Please print and complete ALL the information bellow
                </p>
                <div className='flex flex-col gap-6 max-w-xl'>
                  <div className='flex flex-col gap-4'>
                    <span className='block text-lsm font-normal text-b10'>
                      Name
                    </span>
                    <Controller
                      name='name'
                      control={control}
                      rules={{
                        required: 'Name is required',
                        pattern: {
                          value: nameRegex,
                          message: 'Name is not valid.',
                        },
                      }}
                      render={({ field }) => (
                        <FormInput
                          type='text'
                          placeholder='Enter Name'
                          errorFieldName='name'
                          errors={errors}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className='flex flex-col gap-4'>
                    <span className='block text-lsm font-normal text-b10'>
                      Address Line
                    </span>
                    <div className='flex flex-col gap-4'>
                      <Controller
                        name='address_line_1'
                        control={control}
                        rules={{
                          required: 'Address line 1 is required',
                        }}
                        render={({ field }) => (
                          <FormInput
                            type='text'
                            placeholder='Enter Address Line 1'
                            errorFieldName='address_line_1'
                            errors={errors}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name='address_line_2'
                        control={control}
                        render={({ field }) => (
                          <FormInput
                            type='text'
                            placeholder='Enter Address Line 2'
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className='flex flex-col gap-4'>
                    <span className='block text-lsm font-normal text-b10'>
                      Apartment / Unit
                    </span>
                    <Controller
                      name='appartement'
                      control={control}
                      render={({ field }) => (
                        <FormInput
                          type='text'
                          placeholder='Enter Apartment / Unit'
                          errorFieldName='appartement'
                          errors={errors}
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className='w-full flex gap-4'>
                    <div className='flex-grow flex flex-col gap-4'>
                      <span className='block text-lsm font-normal text-b10'>
                        City
                      </span>
                      <Controller
                        name='city'
                        control={control}
                        rules={{
                          required: 'City is required',
                          pattern: {
                            value: nameRegex,
                            message: 'City is not valid.',
                          },
                        }}
                        render={({ field }) => (
                          <FormInput
                            type='text'
                            placeholder='Enter City'
                            errorFieldName='city'
                            errors={errors}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className='flex-grow flex flex-col gap-4'>
                      <span className='block text-lsm font-normal text-b10'>
                        State / Province
                      </span>
                      <Controller
                        name='state'
                        control={control}
                        rules={{
                          required: 'State is required',
                          pattern: {
                            value: nameRegex,
                            message: 'State is not valid.',
                          },
                        }}
                        render={({ field }) => (
                          <FormInput
                            type='text'
                            placeholder='Enter State'
                            errorFieldName='state'
                            errors={errors}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className='flex-grow flex flex-col gap-4'>
                      <span className='block text-lsm font-normal text-b10'>
                        ZIP / Postal code
                      </span>
                      <Controller
                        name='postal_code'
                        control={control}
                        rules={{
                          required: 'Zip code is required',
                          pattern: {
                            value: zipCodeRegex,
                            message: 'Zip code is not valid.',
                          },
                        }}
                        render={({ field }) => (
                          <FormInput
                            type='text'
                            placeholder='Enter ZIP'
                            errorFieldName='postal_code'
                            errors={errors}
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className='relative w-full'>
                  <img src='/assets/images/cheque.svg' alt='Cheque' />
                </div>

                <div className='flex flex-col gap-6 max-w-xl'>
                  <div className='flex flex-col gap-4 '>
                    <span className='block text-lsm font-normal text-b10'>
                      Name of Bank
                    </span>
                    <div>
                      <Controller
                        name='bank_name'
                        control={control}
                        rules={{
                          required: 'Name of Back is required',
                        }}
                        render={({ field }) => (
                          <FormInput
                            type='text'
                            placeholder='Enter Name of Bank'
                            errorFieldName='bank_name'
                            errors={errors}
                            {...field}
                          />
                        )}
                      />
                      {/* <div className="flex items-center gap-3">
                        <div>
                          <FormInput
                            type="checkbox"
                            className={
                              '!p-0 rounded-none !w-[18px] !h-[18px] text-primary bg-white border-black focus:ring-0 focus:ring-transparent focus:ring-offset-0 '
                            }
                          />
                        </div>
                        <Label
                          className="text-lsm font-normal text-b10"
                          htmlFor="emailMe"
                        >
                          Verify
                        </Label>
                      </div> */}
                    </div>
                  </div>
                  <div className='flex flex-col gap-4 '>
                    <span className='block text-lsm font-normal text-b10'>
                      Account #
                    </span>
                    <div>
                      <Controller
                        name='account_number'
                        control={control}
                        rules={{
                          required: 'Account # is required',
                        }}
                        render={({ field }) => (
                          <FormInput
                            type='text'
                            placeholder='Enter Account #'
                            errorFieldName='account_number'
                            errors={errors}
                            {...field}
                          />
                        )}
                      />
                      {/* <div className="flex items-center gap-3">
                        <div>
                          <FormInput
                            type="checkbox"
                            className={
                              '!p-0 rounded-none !w-[18px] !h-[18px] text-primary bg-white border-black focus:ring-0 focus:ring-transparent focus:ring-offset-0 '
                            }
                          />
                        </div>
                        <Label
                          className="text-lsm font-normal text-b10"
                          htmlFor="emailMe"
                        >
                          Verify
                        </Label>
                      </div> */}
                    </div>
                  </div>
                  <div className='flex flex-col gap-4 '>
                    <span className='block text-lsm font-normal text-b10'>
                      9-Digit Routing #
                    </span>
                    <div>
                      <Controller
                        name='routing_number'
                        control={control}
                        rules={{
                          required: '9-Digit Routing # is required',
                        }}
                        render={({ field }) => (
                          <FormInput
                            type='text'
                            placeholder='Enter 9-Digit Routing #'
                            errorFieldName='routing_number'
                            errors={errors}
                            {...field}
                          />
                        )}
                      />
                      {/* <div className="flex items-center gap-3">
                        <div>
                          <FormInput
                            type="checkbox"
                            className={
                              '!p-0 rounded-none !w-[18px] !h-[18px] text-primary bg-white border-black focus:ring-0 focus:ring-transparent focus:ring-offset-0 '
                            }
                          />
                        </div>
                        <Label
                          className="text-lsm font-normal text-b10"
                          htmlFor="emailMe"
                        >
                          Verify
                        </Label>
                      </div> */}
                    </div>
                  </div>

                  <div className='flex flex-col gap-4 '>
                    <span className='mb-2 block text-lsm font-normal text-b10'>
                      Type of Account
                    </span>
                    <div className='flex gap-6 items-center'>
                      <div className='flex items-center gap-3'>
                        <Radio
                          theme={radioButtonTheme}
                          id={TYPE_OF_ACCOUNT_OPTIONS.CHECKING}
                          {...register('type_of_account', {
                            required: 'Type of Account is required',
                          })}
                          value={TYPE_OF_ACCOUNT_OPTIONS.CHECKING}
                          checked={
                            formData?.type_of_account ===
                            TYPE_OF_ACCOUNT_OPTIONS.CHECKING
                          }
                        />
                        <Label
                          className='text-lsm font-normal text-b10'
                          htmlFor={TYPE_OF_ACCOUNT_OPTIONS.CHECKING}
                        >
                          Checking
                        </Label>
                      </div>
                      <div className='flex items-center gap-3'>
                        <Radio
                          theme={radioButtonTheme}
                          id={TYPE_OF_ACCOUNT_OPTIONS.SAVINGS}
                          {...register('type_of_account', {
                            required: 'Type of Account is required',
                          })}
                          value={TYPE_OF_ACCOUNT_OPTIONS.SAVINGS}
                          checked={
                            formData?.type_of_account ===
                            TYPE_OF_ACCOUNT_OPTIONS.SAVINGS
                          }
                        />
                        <Label
                          className='text-lsm font-normal text-b10'
                          htmlFor={TYPE_OF_ACCOUNT_OPTIONS.SAVINGS}
                        >
                          Savings
                        </Label>
                      </div>
                    </div>
                    {errors.type_of_account && (
                      <p className='text-red-600 text-xxs mt-1'>
                        {errors.type_of_account.message}
                      </p>
                    )}
                  </div>
                </div>

                <div className='flex flex-col gap-4'>
                  <p className='font-semibold font-ibm text-lsm text-black'>
                    Pension ABC is hereby authorized to directly deposit my pay
                    to the account listed above.
                  </p>
                  <div className='grid grid-cols-2 gap-6 items-center'>
                    <div className='flex flex-col gap-6'>
                      <div className='flex flex-col gap-4'>
                        <span className='block text-lsm font-normal text-b10'>
                          Employee Signature
                        </span>
                        <div
                          className={`pt-7 pb-12 border  rounded-lmd ${
                            disableApply
                              ? 'border-toast-error'
                              : 'border-primary'
                          }`}
                        >
                          <div className='border-y border-y-bord-g30'>
                            <ReactSignatureCanvas
                              ref={sigPad}
                              penColor='black'
                              canvasProps={{ width: 500, height: 100 }}
                              onEnd={saveSignature}
                              onBegin={() => clearErrors('signature')}
                            />
                            <input
                              type='hidden'
                              {...register('signature', {
                                required: 'Signature is required',
                              })}
                            />
                          </div>
                        </div>
                        {errors.signature && (
                          <p className='text-red-600 text-xxs mt-1'>
                            {errors.signature.message}
                          </p>
                        )}
                        <div className='flex w-full justify-end items-center gap-2'>
                          <FormSubmit
                            buttonText={'clear'}
                            onClick={(e) => clear(e)}
                            className={'secondary-btn-blue'}
                          />
                          <FormSubmit
                            buttonText={'Apply'}
                            onClick={(e) => trim(e)}
                            className={'primary-btn'}
                          />
                        </div>
                      </div>
                    </div>

                    {trimmedDataURL ? (
                      <div className='bg-bord-g40 w-fit p-2'>
                        <img
                          className=' bg-contain bg-white object-contain'
                          src={trimmedDataURL}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* <div className="max-w-xl">
                  <div className="flex w-fit flex-col gap-4 ">
                    <span className="block text-lsm font-normal text-b10">
                      Date
                    </span>
                    <Datepicker theme={datePickerTheme} maxDate={new Date()} />
                  </div>
                </div> */}
              </div>
              {/* <div className="mt-10 grid grid-cols-2 gap-10">
                <div className="flex flex-col gap-4">
                  <div className=" pb-2  border-b border-bord-g10 flex justify-between items-center">
                    <h3 className="text-base font-semibold text-b10">
                      Uploaded File(s)
                    </h3>
                  </div>
                  {files.length > 0 && (
                    <div className="flex flex-col gap-6">
                      <div className="flex flex-col gap-4">{files}</div>
                    </div>
                  )}
                </div>
                <div className="flex flex-col gap-6">
                  <div
                    {...getRootProps()}
                    className="py-10 relative bg-bord-g40 min-h-60 w-full rounded-md flex flex-col justify-center items-center justify-center cursor-pointer"
                  >
                    {isDragActive && (
                      <div className="absolute pointer-event-none inset-4 border-4 border-dashed border-neutral-gray/50 rounded-xl"></div>
                    )}

                    <input {...getInputProps()} />
                    <div className="w-40 opacity-60">
                      <img
                        src="/assets/images/icons/img-upload.svg"
                        alt="upload"
                      />
                    </div>
                    <p className="mt-4 text-lsm font-semibold text-neutral-gray">
                      Drop files here
                    </p>
                  </div>
                  <div className="flex w-full justify-end">
                    <button
                      className="primary-btn"
                      type="button"
                      onClick={open}
                    >
                      Upload File
                    </button>
                  </div>
                </div>
              </div> */}

              <div className='flex justify-end mt-6'>
                <FormSubmit type={'submit'} buttonText={'Save Changes'} />
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* bottom buttons */}
      <div className='absolute p-4  bottom-0 left-0 w-full flex  items-center justify-between '>
        <FormSubmit
          buttonText={'Previous'}
          className={
            'secondary-btn-white text-black border-black hover:text-black'
          }
          onClick={() =>
            tabHandler(
              PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_CENTER
            )
          }
        />
        <FormSubmit
          buttonText={'Next'}
          className={'primary-btn bg-black hover:bg-black hover:text-white'}
          onClick={() => onSubmit(formData)}
        />
      </div>
    </>
  );
};

BankingDirectDeposit.propTypes = {
  tabHandler: PropTypes.func.isRequired,
};
export default BankingDirectDeposit;
