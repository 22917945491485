export const DATE_DD_MM_YYYY_FORMAT_REGEX =
  /^((0[1-9]|1[0-9]|2[0-9])\/(0[1-9]|1[0-2])|30\/(0[13-9]|1[0-2])|31\/(0[13578]|1[02])|29\/02\/((\d{2}(0[48]|[2468][048]|[13579][26]))|([02468][048]00|[13579][26]00)))\/\d{4}$/;

export const FLAG_Y_N = {
  YES: 'y',
  NO: 'n',
};

export const SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT = {
  HOME: 'Home',
  MY_PROFILE: 'My Profile',
  MY_DOCUMENTS: 'My Documents',
  CHANGE_PASSWORD: 'Change Password',
  CREATE_SCENARIO: 'Create Scenario',
  COMPARE_SCENARIO: 'Compare Scenario',
  SERVICE_RECORD: 'Service Record',
  PENSION_CALCULATOR: 'Pension Calculator',
  BUYBACK_CALCULATOR: 'Buyback Calculator',
  APPLICATION_FORM: 'Pension Application Form',
  APPLICATION_FORM_REACHING_RETIREMENT:
    'Pension Application Form - Reaching Retirement',
  APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION:
    'Pension Application Form - Notify Employer Question',
  APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL:
    'Pension Application Form - Notify Employer Email',
  APPLICATION_FORM_REQUIRED_FILES: 'Pension Application Form - Required Files',
  APPLICATION_FORM_SERVICE_RECORD: 'Pension Application Form - Service Record',
  APPLICATION_FORM_PROFILE: 'Pension Application Form - Profile',
  APPLICATION_FORM_CONTACT: 'Pension Application Form - Contact',
  APPLICATION_FORM_SPOUSAL: 'Pension Application Form - Spousal',
  APPLICATION_FORM_BENEFICIARIES: 'Pension Application Form - Beneficiaries',
  APPLICATION_FORM_PREFERENCES: 'Pension Application Form - Preferences',
  APPLICATION_FORM_ELECTIONS: 'Pension Application Form - Elections',
  APPLICATION_FORM_DOCUMENT_INFO: 'Pension Application Form - Document Info',
  APPLICATION_FORM_DOCUMENT_CENTER:
    'Pension Application Form - Document Center',
  APPLICATION_FORM_BANKING_DIRECT_DEPOSIT:
    'Pension Application Form - Banking Direct Deposit',
  APPLICATION_FORM_REVIEW: 'Pension Application Form - Review',
  APPLICATION_FORM_COMPLETE: 'Pension Application Form - Complete',
};

export const PENSION_APPLICATION_FORM_STEPS = {
  APPLICATION_FORM_REACHING_RETIREMENT: 'reaching_retirement',
  APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION: 'notify_employer_question',
  APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL: 'notify_employer_email',
  APPLICATION_FORM_REQUIRED_FILES: 'required_files',
  APPLICATION_FORM_SERVICE_RECORD: 'service_record',
  APPLICATION_FORM_PROFILE: 'profile',
  APPLICATION_FORM_CONTACT: 'contact',
  APPLICATION_FORM_SPOUSAL: 'spousal',
  APPLICATION_FORM_BENEFICIARIES: 'beneficiaries',
  APPLICATION_FORM_PREFERENCES: 'preferences',
  APPLICATION_FORM_ELECTIONS: 'elections',
  APPLICATION_FORM_DOCUMENT_INFO: 'document_info',
  APPLICATION_FORM_DOCUMENT_CENTER: 'document_center',
  APPLICATION_FORM_BANKING_DIRECT_DEPOSIT: 'banking_direct_deposit',
  APPLICATION_FORM_REVIEW: 'review',
  APPLICATION_FORM_FINISH: 'finish',
};

export const MAPPED_SCREEN_NAME_WITH_STEP = {
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REACHING_RETIREMENT]:
    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REACHING_RETIREMENT,
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REQUIRED_FILES]:
    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REQUIRED_FILES,
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION]:
    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION,
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL]:
    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL,
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SERVICE_RECORD]:
    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SERVICE_RECORD,
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PROFILE]:
    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE,
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_CONTACT]:
    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_CONTACT,
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SPOUSAL]:
    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SPOUSAL,
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BENEFICIARIES]:
    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BENEFICIARIES,
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PREFERENCES]:
    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PREFERENCES,
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_ELECTIONS]:
    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS,
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_DOCUMENT_INFO]:
    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_INFO,
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_DOCUMENT_CENTER]:
    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_CENTER,
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT]:
    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT,
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REVIEW]:
    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REVIEW,
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_COMPLETE]:
    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_FINISH,
};

export const ALLOWED_SCREEN_FOR_GLOBAL_CHATBOT = [
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REACHING_RETIREMENT,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REQUIRED_FILES,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SERVICE_RECORD,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PROFILE,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_CONTACT,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SPOUSAL,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BENEFICIARIES,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PREFERENCES,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_ELECTIONS,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_DOCUMENT_CENTER,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REVIEW,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_COMPLETE,
];

export const ROUTE_PATH = {
  HOME: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  PASSWORD_RESET: '/password-reset',
  MY_PROFILE: '/my-profile',
  DOCUMENT: '/documents',
  CHANGE_PASSWORD: '/change-password',
  CREATE_SCENARIO: '/create-scenario',
  EDIT_SCENARIO: '/edit-scenario',
  COMPARE_SCENARIO: '/compare-scenario',
  SERVICE_RECORD: '/service-record',
  PENSION_CALCULATOR: '/pension-calculator',
  BUYBACK_CALCULATOR: '/buyback-calculator',
  APPLICATION_FORM: '/application-form',
};

export const PROFILE_SECTION_TABS_NAME = {
  PERSONAL: 'Personal',
  CONTACT: 'Contact',
  SPOUSAL: 'Spousal',
  BENEFICIARIES: 'Beneficiaries',
  PREFERENCES: 'Preferences',
};

export const TOAST_MESSAGE_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
};

export const LIFETIME_GUARANTEE_PERIOD_OPTIONS = {
  NONE: 'none',
  TEN_YEARS: '10',
};

export const RETIREMENT_TYPE_OPTIONS = {
  ASAP_WITHOUT_EARLY_REDUCTION: 'asap_without_early_reduction',
  AS_SOON_AS_POSSIBLE: 'as_soon_as_possible',
  AT_DATE: 'at_date',
  AT_AGE: 'at_age',
};

export const CONTACT_NUMBER_TYPE_OPTIONS = {
  CELL: 'cell',
  HOME: 'home',
  WORK: 'work',
  MOBILE: 'mobile',
  OTHER: 'other',
};

export const GENDER_OPTIONS = {
  MALE: 'male',
  FEMALE: 'female',
  OTHER: 'other',
};

export const MARITAL_STATUS_OPTIONS = {
  SINGLE: 'single',
  MARRIED: 'married',
  DIVORCED: 'divorced',
};

export const TYPE_OF_ACCOUNT_OPTIONS = {
  CHECKING: 'checking',
  SAVINGS: 'savings',
};

export const PREFERRED_COMMUNICATION_LANGUAGE_OPTIONS = {
  ENGLISH: 'english',
  FRENCH: 'french',
};

export const SUGGENTIONS_CHIPS_NAME_MAP_FOR_GLOBAL_CHATBOT = {
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PROFILE]: [
    'Go to Personal information',
    'Go to Contact information',
    'Go to Spousal information',
    'Go to Beneficiaries information',
    'Go to Preferences',
    'Please continue',
  ],
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_CONTACT]: [
    'Edit email',
    'Edit Address',
    'Edit phone number',
    'Add new phone number',
    'Add new address',
    'Remove phone number',
    'Remove phone number',
    'Go to Profile information',
    'Go to Spousal information',
    'Go to Beneficiaries information',
    'Go to Preferences',
    'Please continue',
  ],
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SPOUSAL]: [
    'Edit Marital Status',
    'Edit Spouse Name',
    'Edit Spouse D.O.B',
    'Edit Survivor Pension',
    'Edit Election Date',
    'Go to Profile information',
    'Go to Contact information',
    'Go to Beneficiaries information',
    'Go to Preferences',
    'Please continue',
  ],
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_DOCUMENT_INFO]: [
    'I don’t have all of this right now',
    'Back to Dashboard',
    'Yes I have',
  ],
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_DOCUMENT_CENTER]: [
    'Done uploading files',
    'Back to Dashboard',
    'Upload Documents',
  ],
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT]:
    ['No', 'Back to Dashboard', 'Yes please'],
};

export const MAX_DOCUMENT_UPLOAD_SIZE = 2 * 1024 * 1024; // 2MB

export const SELECT_OPTIONS = [
  { value: MARITAL_STATUS_OPTIONS.SINGLE, label: 'Single' },
  { value: MARITAL_STATUS_OPTIONS.MARRIED, label: 'Married' },
  { value: MARITAL_STATUS_OPTIONS.DIVORCED, label: 'Divorced' },
];

export const SELECT_CONTACT_NUMBER_TYPE_OPTIONS = [
  { value: CONTACT_NUMBER_TYPE_OPTIONS.CELL, label: 'Cell' },
  { value: CONTACT_NUMBER_TYPE_OPTIONS.HOME, label: 'Home' },
  { value: CONTACT_NUMBER_TYPE_OPTIONS.WORK, label: 'Work' },
  { value: CONTACT_NUMBER_TYPE_OPTIONS.MOBILE, label: 'Mobile' },
  { value: CONTACT_NUMBER_TYPE_OPTIONS.OTHER, label: 'Other' },
];

export const SCREEN_WISE_STATIC_TEXT = {
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REACHING_RETIREMENT]:
    'Congratulations on reaching retirement! Before we continue, have you officially notified your employer with a resignation letter?',
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REQUIRED_FILES]:
    'Excellent, just ensure you’ve gathered all the necessary documents listed in the requirements.',
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SERVICE_RECORD]:
    'Please review your employment history to make sure everything is correct. Also, it appears you’re eligible for a Buyback option. If you’re unsure about what that entails or need assistance with the process, I’m here to help.',
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PROFILE]:
    'This is your personal profile. Please review each section carefully to confirm its accuracy. If you need any assistance, let me know.',
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_CONTACT]:
    'For the contact information, was there anything you would like to update?',
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SPOUSAL]:
    'Was there any spousal information you would like to update?',
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BENEFICIARIES]:
    'Let me know if you would like to add, update, or remove any beneficiaries.',
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PREFERENCES]:
    'Please select your preferences such as language or email subscriptions.',
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_ELECTIONS]:
    'When would you like to start your retirement?',
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_DOCUMENT_INFO]:
    "We'll need some documents to confirm your identity. A photocopy of your birth certificate, passport, or driver's license. A photocopy of your marriage certificate. A photocopy of your spouse's birth certificate, passport, or driver's license.", // eslint-disable-line
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_DOCUMENT_CENTER]:
    'Ensure that each image is crisp, well-aligned, and bright.',
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT]:
    'For the direct deposit setup, would you prefer that I auto-fill the form using your profile details?',
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REVIEW]:
    "If there's any information you would like to review or edit, now's the time. You're almost at the finish line!", // eslint-disable-line
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_COMPLETE]:
    'Congratulations! Your retirement application has been successfully submitted. You can now download your completed retirement package. You can also find the package in your document center. Congratulations again and thank you for all of your hard work.',
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION]:
    'Would you like to notify your employer now by sending an email? This wil help expedite the retirement process.',
  [SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL]: `Let's compose your email and send it to your employer to ensure a smooth retirement process.`, // eslint-disable-line
};

export const PROFILE_SECTION_TABS_NAME_FOR_AI_RESPONSE = {
  PERSONAL: 'personal-profile',
  CONTACT: 'contact-details',
  SPOUSAL: 'spousal-details',
  BENEFICIARIES: 'beneficiaries',
  PREFERENCES: 'preferences',
};
