import { createSlice } from '@reduxjs/toolkit';
import {
  SCREEN_WISE_STATIC_TEXT,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
} from '../../helper/constant';

const initialState = {
  screenName: '',
  dataForChatBot: {},
  updatedDataFromChatBot: {},
  wantToUpdateData: false,
  isChatBotProcessing: false,
  isSpeecToTextProcessing: false,
  screenStaticText:
    SCREEN_WISE_STATIC_TEXT[
      SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REACHING_RETIREMENT
    ],
  wantToCancelUpdateDataFromChatBot: false,
  redirectScreenStep: null,
  redirectScreenTab: null,
};

const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {
    setScreenName: (state, action) => {
      state.screenName = action.payload;
      state.updatedDataFromChatBot = {};
      state.wantToUpdateData = false;
      switch (action.payload) {
        case SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REACHING_RETIREMENT:
          state.screenStaticText =
            SCREEN_WISE_STATIC_TEXT[
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REACHING_RETIREMENT
            ];
          break;
        case SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REQUIRED_FILES:
          state.screenStaticText =
            SCREEN_WISE_STATIC_TEXT[
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REQUIRED_FILES
            ];
          break;
        case SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SERVICE_RECORD:
          state.screenStaticText =
            SCREEN_WISE_STATIC_TEXT[
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SERVICE_RECORD
            ];
          break;
        case SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PROFILE:
          state.screenStaticText =
            SCREEN_WISE_STATIC_TEXT[
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PROFILE
            ];
          break;
        case SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_CONTACT:
          state.screenStaticText =
            SCREEN_WISE_STATIC_TEXT[
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_CONTACT
            ];
          break;
        case SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SPOUSAL:
          state.screenStaticText =
            SCREEN_WISE_STATIC_TEXT[
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SPOUSAL
            ];
          break;
        case SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BENEFICIARIES:
          state.screenStaticText =
            SCREEN_WISE_STATIC_TEXT[
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BENEFICIARIES
            ];
          break;
        case SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PREFERENCES:
          state.screenStaticText =
            SCREEN_WISE_STATIC_TEXT[
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PREFERENCES
            ];
          break;
        case SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_ELECTIONS:
          state.screenStaticText =
            SCREEN_WISE_STATIC_TEXT[
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_ELECTIONS
            ];
          break;
        case SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_DOCUMENT_INFO:
          state.screenStaticText =
            SCREEN_WISE_STATIC_TEXT[
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_DOCUMENT_INFO
            ];
          break;
        case SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_DOCUMENT_CENTER:
          state.screenStaticText =
            SCREEN_WISE_STATIC_TEXT[
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_DOCUMENT_CENTER
            ];
          break;
        case SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT:
          state.screenStaticText =
            SCREEN_WISE_STATIC_TEXT[
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT
            ];
          break;
        case SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REVIEW:
          state.screenStaticText =
            SCREEN_WISE_STATIC_TEXT[
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REVIEW
            ];
          break;
        case SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_COMPLETE:
          state.screenStaticText =
            SCREEN_WISE_STATIC_TEXT[
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_COMPLETE
            ];
          break;
        case SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION:
          state.screenStaticText =
            SCREEN_WISE_STATIC_TEXT[
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION
            ];
          break;
        case SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL:
          state.screenStaticText =
            SCREEN_WISE_STATIC_TEXT[
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL
            ];
          break;

        default:
          break;
      }
    },
    setDataForChatBot: (state, action) => {
      state.dataForChatBot = action.payload;
    },
    setUpdatedDataFromChatBot: (state, action) => {
      if (!state.wantToCancelUpdateDataFromChatBot) {
        state.updatedDataFromChatBot = action.payload;
        state.wantToUpdateData = true;
      } else {
        state.wantToCancelUpdateDataFromChatBot = false;
        state.updatedDataFromChatBot = {
          text: 'Your updating request cancelled successfully. Is there anything else you would like to update or change?',
          redable_text:
            'Your updating request cancelled successfully. Is there anything else you would like to update or change?',
        };
      }
    },
    setWantToUpdateData: (state, action) => {
      state.wantToUpdateData = action.payload;
    },
    setIsChatBotProcessing: (state, action) => {
      state.isChatBotProcessing = action.payload;
    },
    setIsSpeecToTextProcessing: (state, action) => {
      state.isSpeecToTextProcessing = action.payload;
    },
    setWantToCancelUpdateDataFromChatBot: (state, action) => {
      state.wantToCancelUpdateDataFromChatBot = action.payload;
    },
    setRedirectScreenStep: (state, action) => {
      state.redirectScreenStep = action.payload;
    },
    setRedirectScreenTab: (state, action) => {
      state.redirectScreenTab = action.payload;
    },
  },
});

export const {
  setScreenName,
  setDataForChatBot,
  setUpdatedDataFromChatBot,
  setWantToUpdateData,
  setIsChatBotProcessing,
  setIsSpeecToTextProcessing,
  setWantToCancelUpdateDataFromChatBot,
  setRedirectScreenStep,
  setRedirectScreenTab,
} = chatbotSlice.actions;

export default chatbotSlice.reducer;
