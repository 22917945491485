import React, { useEffect, useState, useRef } from 'react';
import { Tabs } from 'flowbite-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ChatbotInput from '../../components/ChatbotInput/ChatbotInput';
import { FormSubmit, FullScreenLoader } from '../../components/Generic';
import useResizeHeight from '../../hooks/useResizeHeight';
import {
  BankingDirectDeposit,
  DocumentCenter,
  Elections,
  FilesDocuments,
  NoFlow,
  // PensionBenefitsComparison,
  ProfileSetup,
  Review,
  ServiceRecords,
  // SubmitBuyBack,
} from '../../components/ApplicationFormComponents';
import {
  setIsSpeecToTextProcessing,
  setRedirectScreenStep,
  setScreenName,
  setWantToCancelUpdateDataFromChatBot,
} from '../../features/chatbot/chatbot';
import {
  PENSION_APPLICATION_FORM_STEPS,
  ROUTE_PATH,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
  SUGGENTIONS_CHIPS_NAME_MAP_FOR_GLOBAL_CHATBOT,
} from '../../helper/constant';
import { fetchPensionApplicationFormData } from '../../features/pensionApplicationForm/pensionApplicationFormThunk';
import Completed from '../../components/ApplicationFormComponents/Completed';
import TypingEffect from '../../components/ChatbotInput/TypingEffect';
import AudioPlayer from '../../components/Generic/AudioPlayer';
import { updatePensionApplicationFormStep } from '../../helper/function';
import { setUserInfo } from '../../features/user/userSlice';
import axiosInstance from '../../api/axiosInstance';
import { setPensionApplicationFormActiveTab } from '../../features/pensionApplicationForm/pensionApplicationFormSlice';
import DocumentInfo from '../../components/ApplicationFormComponents/DocumentInfo';

const customTabTheme = {
  base: 'block h-full',
  tablist: {
    base: 'hidden',
    tabitem: {},
  },
  tabitemcontainer: {
    base: ' p-0 h-full',
  },
  tabpanel: 'p-0 h-full',
};

const ApplicationForm = () => {
  useResizeHeight('dashPanel');
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location?.pathname;
  const tabsRef = useRef(null);
  const navigate = useNavigate();

  const {
    isChatBotProcessing,
    updatedDataFromChatBot,
    screenName,
    isSpeecToTextProcessing,
    screenStaticText,
    redirectScreenStep,
  } = useSelector((state) => state.chatbot);
  const { userInfo } = useSelector((state) => state.user);
  const { pensionApplicationFormActiveTab } = useSelector(
    (state) => state.pensionApplicationForm
  );

  const [audioData, setAudioData] = useState({
    data: '',
    format: '',
  });
  // const [shouldRenderChart, setShouldRenderChart] = useState(false);

  const tabHandler = (tab) => {
    dispatch(setPensionApplicationFormActiveTab(tab));
    // if (tab === 8) {
    //   setShouldRenderChart(true);
    // } else {
    //   setShouldRenderChart(false);
    // }
  };

  useEffect(() => {
    if (pathname === ROUTE_PATH.APPLICATION_FORM) {
      switch (pensionApplicationFormActiveTab) {
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REACHING_RETIREMENT:
          dispatch(
            setScreenName(
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REACHING_RETIREMENT
            )
          );
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REQUIRED_FILES:
          dispatch(
            setScreenName(
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REQUIRED_FILES
            )
          );
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SERVICE_RECORD:
          dispatch(
            setScreenName(
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SERVICE_RECORD
            )
          );
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS:
          dispatch(
            setScreenName(
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_ELECTIONS
            )
          );
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_INFO:
          dispatch(
            setScreenName(
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_DOCUMENT_INFO
            )
          );
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_CENTER:
          dispatch(
            setScreenName(
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_DOCUMENT_CENTER
            )
          );
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT:
          dispatch(
            setScreenName(
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT
            )
          );
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REVIEW:
          dispatch(
            setScreenName(
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REVIEW
            )
          );
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_FINISH:
          dispatch(
            setScreenName(
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_COMPLETE
            )
          );
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION:
          dispatch(
            setScreenName(
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION
            )
          );
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL:
          dispatch(
            setScreenName(
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL
            )
          );
          break;
        default:
          break;
      }
    }
  }, [pensionApplicationFormActiveTab]);

  useEffect(() => {
    let didCancel = false;
    if (!didCancel) {
      dispatch(fetchPensionApplicationFormData());
    }
    return () => {
      didCancel = true;
      dispatch(setPensionApplicationFormActiveTab(null));
    };
  }, []);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REACHING_RETIREMENT &&
      updatedDataFromChatBot?.is_no_flow
    ) {
      tabHandler(
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION
      );
    } else if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_REACHING_RETIREMENT &&
      (updatedDataFromChatBot?.want_submit || updatedDataFromChatBot?.is_next)
    ) {
      tabHandler(
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REQUIRED_FILES
      );
    } else if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION &&
      updatedDataFromChatBot?.want_submit
    ) {
      if (updatedDataFromChatBot?.is_next) {
        tabHandler(
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL
        );
      } else if (updatedDataFromChatBot?.is_previous) {
        tabHandler(
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REACHING_RETIREMENT
        );
      }
    }
  }, [screenName, updatedDataFromChatBot]);

  useEffect(() => {
    async function fetchAudioData() {
      try {
        dispatch(setIsSpeecToTextProcessing(true));
        const options = {
          method: 'POST',
          headers: {
            accept: '*/*',
            'content-type': 'application/json',
            Authorization: process.env.REACT_APP_SPEECHIFY_API_KEY,
          },
          body: JSON.stringify({
            input: updatedDataFromChatBot?.redable_text || screenStaticText,
            voice_id: 'kristy',
          }),
        };

        const result = await fetch(
          'https://api.sws.speechify.com/v1/audio/speech',
          options
        );
        const modifiedResult = await result.json();
        setAudioData({
          data: modifiedResult.audio_data,
          format: modifiedResult.audio_format,
        });
        dispatch(setIsSpeecToTextProcessing(false));
      } catch (error) {
        dispatch(setIsSpeecToTextProcessing(false));
        console.error(error);
      }
    }
    if (
      (updatedDataFromChatBot?.redable_text || screenStaticText) &&
      userInfo?.data?.is_voice_over_enabled
    ) {
      fetchAudioData();
    }
  }, [
    userInfo?.data?.is_voice_over_enabled,
    updatedDataFromChatBot,
    screenStaticText,
  ]);

  useEffect(() => {
    if (userInfo?.data?.id && !userInfo?.data?.is_applicable_for_retirement) {
      navigate(ROUTE_PATH.HOME);
    }
  }, [userInfo]);

  useEffect(() => {
    if (
      pathname === ROUTE_PATH.APPLICATION_FORM &&
      (redirectScreenStep || redirectScreenStep === 0)
    ) {
      switch (redirectScreenStep) {
        case 1:
          tabHandler(
            PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SERVICE_RECORD
          );
          dispatch(setRedirectScreenStep(null));
          break;
        case 2:
          tabHandler(PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE);
          break;
        case 3:
          tabHandler(PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS);
          dispatch(setRedirectScreenStep(null));
          break;
        case 4:
          tabHandler(
            PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_CENTER
          );
          dispatch(setRedirectScreenStep(null));
          break;
        case 5:
          tabHandler(
            PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT
          );
          dispatch(setRedirectScreenStep(null));
          break;
        case 6:
          tabHandler(PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_FINISH);
          dispatch(setRedirectScreenStep(null));
          break;
        default:
          break;
      }
    }
  }, [redirectScreenStep]);

  useEffect(() => {
    if (tabsRef.current) {
      switch (pensionApplicationFormActiveTab) {
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REACHING_RETIREMENT:
          tabsRef.current.setActiveTab(0);
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REQUIRED_FILES:
          tabsRef.current.setActiveTab(1);
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SERVICE_RECORD:
          tabsRef.current.setActiveTab(2);
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE:
          tabsRef.current.setActiveTab(3);
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS:
          tabsRef.current.setActiveTab(4);
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_CENTER:
          tabsRef.current.setActiveTab(5);
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT:
          tabsRef.current.setActiveTab(6);
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REVIEW:
          tabsRef.current.setActiveTab(7);
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_FINISH:
          tabsRef.current.setActiveTab(8);
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION:
          tabsRef.current.setActiveTab(9);
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL:
          tabsRef.current.setActiveTab(10);
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_INFO:
          tabsRef.current.setActiveTab(11);
          break;
        default:
          break;
      }
    }
  }, [pensionApplicationFormActiveTab]);

  const chatBotMuteHandler = async () => {
    try {
      const response = await axiosInstance.put('update-voice-over');
      dispatch(
        setUserInfo({
          ...userInfo,
          data: {
            ...userInfo.data,
            is_voice_over_enabled: response.data.data.is_voice_over_enabled,
          },
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const HandleDisplayMessageFromChatbot = () => {
    switch (pensionApplicationFormActiveTab) {
      case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REACHING_RETIREMENT:
        return (
          <div className='flex flex-col gap-6'>
            <h2 className='text-xxxl font-semibold font-ibm text-b10 text-center'>
              Congratulations on reaching retirement!
            </h2>
            <p className='text-slg font-medium font-ibm text-b10 '>
              Before we continue, have you officially notified your employer
              with a resignation letter?
            </p>
          </div>
        );
      case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REQUIRED_FILES:
        return (
          <div className='flex flex-col gap-6'>
            <p className='text-slg font-medium font-ibm text-b10 '>
              Excellent, just ensure you’ve gathered all the necessary documents
              listed in the requirements.
            </p>
          </div>
        );
      case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SERVICE_RECORD:
        return (
          <div className='flex flex-col gap-6'>
            <p className='text-slg font-medium font-ibm text-b10 '>
              Please review your employment history to make sure everything is
              correct.
            </p>
            <p className='text-slg font-medium font-ibm text-b10 '>
              Also, it appears you’re eligible for a Buyback option. If you’re
              unsure about what that entails or need assistance with the
              process, I’m here to help.
            </p>
          </div>
        );
      case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE:
        return (
          <div className='flex flex-col gap-6'>
            <p className='text-slg font-medium font-ibm text-b10 '>
              {screenStaticText}
            </p>
          </div>
        );
      case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS:
        return (
          <div className='flex flex-col gap-6'>
            <p className='text-slg font-medium font-ibm text-b10'>
              When would you like to start your retirement?
            </p>
          </div>
        );
      case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_INFO:
        return (
          <div className='flex flex-col gap-6'>
            <h2 className='text-xxl font-semibold font-ibm text-b10'>
              We&apos;ll need some documents to confirm your identity.
            </h2>
            <li>
              A photocopy of your birth certificate, passport, or driver&apos;s
              license.
            </li>
            <li>A photocopy of your marriage certificate</li>
            <li>
              A photocopy of your spouse&apos;s birth certificate, passport, or
              driver&apos;s license.
            </li>
          </div>
        );
      case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_CENTER:
        return (
          <div className='flex flex-col gap-6'>
            <p className='text-slg font-medium font-ibm text-b10 '>
              Ensure that each image is crisp, well-aligned, and bright.
            </p>
          </div>
        );
      case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT:
        return (
          <div className='flex flex-col gap-6'>
            <p className='text-slg font-medium font-ibm text-b10 '>
              For the direct deposit setup, would you prefer that I auto-fill
              the form using your profile details?
            </p>
          </div>
        );
      case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REVIEW:
        return (
          <div className='flex flex-col gap-6'>
            <p className='text-slg font-medium font-ibm text-b10 '>
              {`If there's any information you would like to review or edit, now’s
              the time. You’re almost at the finish line!`}
            </p>
          </div>
        );
      case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_FINISH:
        return (
          <div className='flex flex-col gap-6'>
            <p className='text-slg font-medium font-ibm text-b10 '>
              Congratulations!
            </p>
            <p className='text-slg font-medium font-ibm text-b10 '>
              Your retirement application has been successfully submitted.
            </p>
            <p className='text-slg font-medium font-ibm text-b10 '>
              You can now download your completed retirement package. You can
              also find the package in your document center.
            </p>
            <p className='text-slg font-medium font-ibm text-b10 '>
              Congratulations again and thank you for all of your hard work.
            </p>
          </div>
        );
      case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION:
        return (
          <div className='flex flex-col gap-6'>
            <p className='text-slg font-medium font-ibm text-b10 '>
              Would you like to notify your employer now by sending an email?
              This wil help expedite the retirement process.
            </p>
          </div>
        );
      case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL:
        return (
          <div className='flex flex-col gap-6'>
            <p className='text-slg font-medium font-ibm text-b10 '>
              {
                `Let's compose your email and send it to your employer to ensure a smooth retirement process.` // eslint-disable-line
              }
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  if (pensionApplicationFormActiveTab === null) {
    return <FullScreenLoader />;
  }

  return (
    <div className='mt-4 flex gap-2'>
      <div className='w-1/3 flex flex-col flex-shrink-0 justify-between border-r border-r-bord-g10 min-h-full'>
        <div className='px-2 py-6  '>
          {pensionApplicationFormActiveTab !==
            PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REACHING_RETIREMENT &&
            pensionApplicationFormActiveTab !==
              PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION &&
            pensionApplicationFormActiveTab !==
              PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL && (
              <div className='mb-10 flex gap-10 w-full justify-between relative after:h-px after:w-full after:bg-bord-g10 after:absolute after:top-1/2 after:-translate-y-1/2 after:z-0'>
                <button
                  className={`text-lsm font-normal  w-8 h-8 border  aspect-square rounded-full duration-300 z-1 ${
                    pensionApplicationFormActiveTab ===
                    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SERVICE_RECORD
                      ? 'bg-primary text-white'
                      : 'bg-light-gray text-neutral-gray border-bord-g10'
                  }`}
                  onClick={() =>
                    dispatch(
                      setPensionApplicationFormActiveTab(
                        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SERVICE_RECORD
                      )
                    )
                  }
                >
                  1
                </button>
                <button
                  className={`text-lsm font-normal  w-8 h-8 border  aspect-square rounded-full duration-300 z-1 ${
                    pensionApplicationFormActiveTab ===
                    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE
                      ? 'bg-primary text-white'
                      : 'bg-light-gray text-neutral-gray border-bord-g10'
                  }`}
                  onClick={() =>
                    dispatch(
                      setPensionApplicationFormActiveTab(
                        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE
                      )
                    )
                  }
                >
                  2
                </button>
                <button
                  className={`text-lsm font-normal  w-8 h-8 border  aspect-square rounded-full duration-300 z-1 ${
                    pensionApplicationFormActiveTab ===
                    PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS
                      ? 'bg-primary text-white'
                      : 'bg-light-gray text-neutral-gray border-bord-g10'
                  }`}
                  onClick={() =>
                    dispatch(
                      setPensionApplicationFormActiveTab(
                        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS
                      )
                    )
                  }
                >
                  3
                </button>
                <button
                  className={`text-lsm font-normal  w-8 h-8 border  aspect-square rounded-full duration-300 z-1 ${
                    pensionApplicationFormActiveTab ===
                      PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_INFO ||
                    pensionApplicationFormActiveTab ===
                      PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_CENTER
                      ? 'bg-primary text-white'
                      : 'bg-light-gray text-neutral-gray border-bord-g10'
                  }`}
                  onClick={() =>
                    dispatch(
                      setPensionApplicationFormActiveTab(
                        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_INFO
                      )
                    )
                  }
                >
                  4
                </button>
                <button
                  className={`text-lsm font-normal  w-8 h-8 border  aspect-square rounded-full duration-300 z-1 ${
                    pensionApplicationFormActiveTab ===
                      PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT ||
                    pensionApplicationFormActiveTab ===
                      PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REVIEW ||
                    pensionApplicationFormActiveTab ===
                      PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_FINISH
                      ? 'bg-primary text-white'
                      : 'bg-light-gray text-neutral-gray border-bord-g10'
                  }`}
                  onClick={() =>
                    dispatch(
                      setPensionApplicationFormActiveTab(
                        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT
                      )
                    )
                  }
                >
                  5
                </button>
              </div>
            )}

          {isChatBotProcessing || isSpeecToTextProcessing ? (
            <div className='flex flex-row justify-between items-center'>
              <div>Processing...</div>
              {isChatBotProcessing && (
                <img
                  src='/assets/images/icons/close.svg'
                  alt='Cancel'
                  height={30}
                  width={30}
                  className='cursor-pointer'
                  onClick={() =>
                    dispatch(setWantToCancelUpdateDataFromChatBot(true))
                  }
                />
              )}
            </div>
          ) : (updatedDataFromChatBot?.text ||
              updatedDataFromChatBot?.redable_text) &&
            !isChatBotProcessing &&
            !isSpeecToTextProcessing ? (
            <div className='flex flex-col gap-6 max-h-[300px] overflow-auto'>
              <div className='flex justify-between items-start'>
                <p className='text-slg font-medium font-ibm text-b10 '>
                  <TypingEffect
                    text={
                      updatedDataFromChatBot?.text ||
                      updatedDataFromChatBot?.redable_text ||
                      ''
                    }
                    speed={10}
                  />
                  {/* {updatedDataFromChatBot.text} */}
                  <AudioPlayer
                    audioData={audioData?.data}
                    audioFormat={audioData?.format}
                  />
                </p>
                <button
                  className='aspect-square rounded-full flex-shrink-0 mt-1'
                  onClick={chatBotMuteHandler}
                >
                  {!userInfo?.data?.is_voice_over_enabled ? (
                    <img
                      src='/assets/images/icons/mute.svg'
                      width={30}
                      alt='unmute'
                    />
                  ) : (
                    <img
                      src='/assets/images/icons/unmute.svg'
                      width={30}
                      alt='unmute'
                    />
                  )}
                </button>
              </div>
            </div>
          ) : (
            <div className='flex justify-between items-start'>
              {HandleDisplayMessageFromChatbot()}
              <button
                className='aspect-square rounded-full flex-shrink-0 mt-1'
                onClick={chatBotMuteHandler}
              >
                {!userInfo?.data?.is_voice_over_enabled ? (
                  <img
                    src='/assets/images/icons/mute.svg'
                    width={30}
                    alt='unmute'
                  />
                ) : (
                  <img
                    src='/assets/images/icons/unmute.svg'
                    width={30}
                    alt='unmute'
                  />
                )}
              </button>
              <AudioPlayer
                audioData={audioData?.data}
                audioFormat={audioData?.format}
              />
            </div>
          )}
        </div>

        <div className='pt-2'>
          <span className='block text-xxs font-normal text-neutral-gray'>
            Suggestions:{' '}
          </span>

          <div className='py-4 flex  gap-4 flex-wrap  max-h-[300px] overflow-auto'>
            {SUGGENTIONS_CHIPS_NAME_MAP_FOR_GLOBAL_CHATBOT?.[screenName]
              ?.length > 0 ? (
              SUGGENTIONS_CHIPS_NAME_MAP_FOR_GLOBAL_CHATBOT[screenName]?.map(
                (suggetion, idx) => (
                  <FormSubmit
                    key={`${suggetion}_${idx}`}
                    className={`shrink-0 ${
                      idx ===
                      SUGGENTIONS_CHIPS_NAME_MAP_FOR_GLOBAL_CHATBOT?.[
                        screenName
                      ]?.length -
                        1
                        ? 'primary-btn'
                        : 'secondary-btn-blue'
                    } `}
                    buttonText={suggetion}
                  />
                )
              )
            ) : (
              <>
                <FormSubmit
                  className='secondary-btn-blue'
                  buttonText='Back to Dashboard'
                />
                <FormSubmit className='primary-btn' buttonText='Yes, I have' />
                <FormSubmit
                  className='primary-btn'
                  buttonText='No, I haven’t'
                />
              </>
            )}
          </div>

          <div className='mt-4 pr-2'>
            <ChatbotInput listen={true} sidebar={true} />
          </div>
          <span className='mt-4 block text-xxs font-normal text-neutral-gray'>
            *Piddy® can assist you with all of your pension needs. Just speak or
            type your request.
          </span>
        </div>
      </div>

      <div id='dashPanel' className='w-full p-4 relative overflow-auto'>
        <div className='h-[calc(100%_-_52px)] overflow-auto'>
          <Tabs theme={customTabTheme} ref={tabsRef}>
            <Tabs.Item
              active={
                pensionApplicationFormActiveTab ===
                PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REACHING_RETIREMENT
              }
            >
              <div className='flex flex-col gap-6'>
                <h2 className='text-xxxl font-semibold font-ibm text-b10 text-center'>
                  Congratulations on reaching retirement!
                </h2>
                <p className='text-slg font-medium font-ibm text-b10 '>
                  Before we continue, have you officially notified your employer
                  with a resignation letter?
                </p>
              </div>
              <div className='absolute p-4 bottom-0 left-0 w-full flex  items-center justify-between'>
                <FormSubmit
                  buttonText={'Back to Dashboard'}
                  className='secondary-btn-white text-black border-black hover:text-black primary-btn'
                  onClick={() => navigate(ROUTE_PATH.HOME)}
                />
                <FormSubmit
                  buttonText="No, I haven't"
                  className={
                    'primary-btn bg-black hover:bg-black hover:text-white'
                  }
                  onClick={() => {
                    updatePensionApplicationFormStep({
                      step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION,
                    });
                    tabHandler(
                      PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION
                    );
                  }}
                />
                <FormSubmit
                  buttonText='Yes, I have'
                  className={
                    'primary-btn bg-black hover:bg-black hover:text-white'
                  }
                  onClick={() =>
                    tabHandler(
                      PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REQUIRED_FILES
                    )
                  }
                />
              </div>
            </Tabs.Item>
            <Tabs.Item
              active={
                pensionApplicationFormActiveTab ===
                PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REQUIRED_FILES
              }
            >
              <FilesDocuments tabHandler={tabHandler} />
            </Tabs.Item>
            <Tabs.Item
              active={
                pensionApplicationFormActiveTab ===
                PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SERVICE_RECORD
              }
            >
              <ServiceRecords tabHandler={tabHandler} />
            </Tabs.Item>
            <Tabs.Item
              active={
                pensionApplicationFormActiveTab ===
                  PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE ||
                pensionApplicationFormActiveTab ===
                  PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_CONTACT ||
                pensionApplicationFormActiveTab ===
                  PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SPOUSAL ||
                pensionApplicationFormActiveTab ===
                  PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BENEFICIARIES ||
                pensionApplicationFormActiveTab ===
                  PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PREFERENCES
              }
            >
              <ProfileSetup
                tabHandler={tabHandler}
                applicationFormActiveTab={pensionApplicationFormActiveTab}
              />
            </Tabs.Item>
            <Tabs.Item
              active={
                pensionApplicationFormActiveTab ===
                PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS
              }
            >
              <Elections tabHandler={tabHandler} />
            </Tabs.Item>
            <Tabs.Item
              active={
                pensionApplicationFormActiveTab ===
                PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_CENTER
              }
            >
              <DocumentCenter tabHandler={tabHandler} />
            </Tabs.Item>
            <Tabs.Item
              active={
                pensionApplicationFormActiveTab ===
                PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BANKING_DIRECT_DEPOSIT
              }
            >
              <BankingDirectDeposit tabHandler={tabHandler} />
            </Tabs.Item>
            <Tabs.Item
              active={
                pensionApplicationFormActiveTab ===
                PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REVIEW
              }
            >
              <Review tabHandler={tabHandler} />
            </Tabs.Item>
            <Tabs.Item
              active={
                pensionApplicationFormActiveTab ===
                PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_FINISH
              }
            >
              <Completed tabHandler={tabHandler} />
            </Tabs.Item>
            <Tabs.Item
              active={
                pensionApplicationFormActiveTab ===
                PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION
              }
            >
              <div className='flex flex-col gap-6'>
                <div className='absolute p-4 bottom-0 left-0 w-full flex  items-center justify-between'>
                  <FormSubmit
                    buttonText='Previous'
                    className='secondary-btn-white text-black border-black hover:text-black primary-btn'
                    onClick={() =>
                      tabHandler(
                        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REACHING_RETIREMENT
                      )
                    }
                  />
                  <FormSubmit
                    buttonText='Next'
                    className={
                      'primary-btn bg-black hover:bg-black hover:text-white'
                    }
                    onClick={() =>
                      tabHandler(
                        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL
                      )
                    }
                  />
                </div>
              </div>
            </Tabs.Item>
            <Tabs.Item
              active={
                pensionApplicationFormActiveTab ===
                PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL
              }
            >
              <NoFlow tabHandler={tabHandler} />
            </Tabs.Item>
            <Tabs.Item
              active={
                pensionApplicationFormActiveTab ===
                PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_DOCUMENT_INFO
              }
            >
              <DocumentInfo tabHandler={tabHandler} />
            </Tabs.Item>
            {/* <Tabs.Item>
              {shouldRenderChart && (
                <PensionBenefitsComparison tabHandler={tabHandler} />
              )}
            </Tabs.Item>
            <Tabs.Item>
              <SubmitBuyBack tabHandler={tabHandler} />
            </Tabs.Item> */}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ApplicationForm;
