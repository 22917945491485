import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css'; // import styles
import 'quill/dist/quill.snow.css'; // import Quill styles
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { FormSubmit } from '../Generic';
import { updatePensionApplicationFormStep } from '../../helper/function';
import {
  PENSION_APPLICATION_FORM_STEPS,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
} from '../../helper/constant';
import { setDataForChatBot, setWantToUpdateData } from '../../features/chatbot/chatbot';
import { STORAGE_KEYS } from '../../helper';

function NoFlow({ tabHandler }) {
  const dispatch = useDispatch();

  const { updatedDataFromChatBot, screenName, wantToUpdateData } = useSelector(
    (state) => state.chatbot
  );

  const [recipients, setRecipients] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSend = () => {
    console.log({ recipients, subject, message });
  };

  const handleNextButton = async () => {
    await updatePensionApplicationFormStep({
      step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL,
    });
    tabHandler(PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REQUIRED_FILES);
  };

  useEffect(() => {
    if (
      screenName ===
      SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL
    ) {
      dispatch(
        setDataForChatBot({
          resignation_email_letter: {
            resignation_letter_email_to: '',
            email_subject: '',
            message: '',
            is_send_true: false,
          },
          node: 'notify_employer_email',
          sender: localStorage.getItem(STORAGE_KEYS.token),
        })
      );
    }
  }, [screenName]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL &&
      updatedDataFromChatBot?.want_submit
    ) {
      if (updatedDataFromChatBot?.is_next) {
        tabHandler(
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_REQUIRED_FILES
        );
      } else if (updatedDataFromChatBot?.is_previous) {
        tabHandler(
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION
        );
      }
    }
  }, [screenName, updatedDataFromChatBot]);

  useEffect(() => {
    if (
      screenName ===
        SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_NOTIFY_EMPLOYER_EMAIL &&
      wantToUpdateData &&
      !updatedDataFromChatBot?.is_error_occure &&
      !updatedDataFromChatBot?.want_submit
    ) {
      //TODO: update data in form
      //console.log('updatedDataFromChatBot', updatedDataFromChatBot);
      dispatch(setWantToUpdateData(false));
    }
  }, [screenName, wantToUpdateData, updatedDataFromChatBot]);

  return (
    <>
      <div className='relative p-4 bg-white rounded-lg shadow h-full'>
        <h2 className='text-xl font-semibold mb-4'>New Message</h2>

        <div className='mb-4'>
          <label
            htmlFor='recipients'
            className='block text-sm font-medium text-gray-700 mb-1'
          >
            To
          </label>
          <input
            id='recipients'
            type='text'
            value={recipients}
            onChange={(e) => setRecipients(e.target.value)}
            placeholder='Enter Recipients'
            className='w-full p-2 border border-gray-300 rounded-md'
          />
        </div>

        <div className='mb-4'>
          <label
            htmlFor='subject'
            className='block text-sm font-medium text-gray-700 mb-1'
          >
            Subject
          </label>
          <input
            id='subject'
            type='text'
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder='Enter Subject'
            className='w-full p-2 border border-gray-300 rounded-md'
          />
        </div>

        <div className='mb-4'>
          <label
            htmlFor='message'
            className='block text-sm font-medium text-gray-700 mb-1'
          >
            Message
          </label>
          <ReactQuill
            value={message}
            onChange={setMessage}
            placeholder='Enter Message'
            className='bg-white border border-gray-300 rounded-md'
          />
        </div>

        <div className='flex justify-end items-center'>
          <button className='mr-2 p-2 rounded-full bg-gray-100 hover:bg-gray-200'>
            <img src='/assets/images/icons/attachment.svg' alt='attachment' />
          </button>
          <button onClick={handleSend} className='primary-btn'>
            Send
          </button>
        </div>
      </div>
      <div className='absolute p-4  bottom-0 left-0 w-full flex  items-center justify-between '>
        <FormSubmit
          buttonText={'Previous'}
          className={
            'secondary-btn-white text-black border-black hover:text-black'
          }
          onClick={() =>
            tabHandler(
              PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_NOTIFY_EMPLOYER_QUESTION
            )
          }
        />
        <FormSubmit
          buttonText={'Next'}
          className={'primary-btn bg-black hover:bg-black hover:text-white'}
          onClick={handleNextButton}
        />
      </div>
    </>
  );
}

NoFlow.propTypes = {
  tabHandler: PropTypes.func,
};
NoFlow.defaultProps = {
  tabHandler: () => {},
};

export default NoFlow;
