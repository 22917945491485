import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs } from 'flowbite-react';
import PersonalInformation from '../ProfileComponent/PersonalInformation';
import Contact from '../ProfileComponent/Contact';
import Spousal from '../ProfileComponent/Spousal';
import Beneficiaries from '../ProfileComponent/Beneficiaries';
import Preferences from '../ProfileComponent/Preferences';
import { FormSubmit } from '../Generic';
import ProfileHeaderCard from '../ProfileComponent/ProfileHeaderCard';
import {
  setRedirectScreenStep,
  setRedirectScreenTab,
  setScreenName,
} from '../../features/chatbot/chatbot';
import {
  MAPPED_SCREEN_NAME_WITH_STEP,
  MARITAL_STATUS_OPTIONS,
  PENSION_APPLICATION_FORM_STEPS,
  PROFILE_SECTION_TABS_NAME,
  PROFILE_SECTION_TABS_NAME_FOR_AI_RESPONSE,
  ROUTE_PATH,
  SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT,
} from '../../helper/constant';
import { updatePensionApplicationFormStep } from '../../helper/function';
import axiosInstance from '../../api/axiosInstance';

const customTabTheme = {
  base: 'block',
  tablist: {
    base: 'hidden',
    tabitem: {},
  },
  tabitemcontainer: {
    base: 'p-0',
  },
  tabpanel: {
    base: 'p-0',
  },
};

const ProfileSetup = ({ tabHandler, applicationFormActiveTab }) => {
  const tabsRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location?.pathname;

  const { userInfo } = useSelector((state) => state.user);
  const { pensionApplicationFormData } = useSelector(
    (state) => state.pensionApplicationForm
  );
  const { screenName, redirectScreenStep, redirectScreenTab } = useSelector(
    (state) => state.chatbot
  );

  const lastActiveStep = pensionApplicationFormData?.step;

  const [activeTab, setActiveTab] = useState(0);

  const changeScreen = async () => {
    const nextActiveTab = activeTab + 1;
    const updatedPensionApplicationFormData = await axiosInstance.get(
      'retirement/form/get'
    );
    const lastUpdatedStep = updatedPensionApplicationFormData?.data?.data?.step;
    if (
      nextActiveTab === 1 &&
      (lastActiveStep ===
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE ||
        lastUpdatedStep ===
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE)
    ) {
      await updatePensionApplicationFormStep({
        step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_CONTACT,
      });
    } else if (
      nextActiveTab === 2 &&
      (lastActiveStep ===
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_CONTACT ||
        lastUpdatedStep ===
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_CONTACT)
    ) {
      await updatePensionApplicationFormStep({
        step:
          userInfo?.data?.marital_status === MARITAL_STATUS_OPTIONS.SINGLE
            ? PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BENEFICIARIES
            : PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SPOUSAL,
      });
    } else if (
      nextActiveTab === 3 &&
      (lastActiveStep ===
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SPOUSAL ||
        lastUpdatedStep ===
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SPOUSAL)
    ) {
      await updatePensionApplicationFormStep({
        step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BENEFICIARIES,
      });
    } else if (
      nextActiveTab === 4 &&
      (lastActiveStep ===
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BENEFICIARIES ||
        lastUpdatedStep ===
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BENEFICIARIES)
    ) {
      await updatePensionApplicationFormStep({
        step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PREFERENCES,
      });
    } else if (
      activeTab === 4 &&
      (lastActiveStep ===
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PREFERENCES ||
        lastUpdatedStep ===
          PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PREFERENCES)
    ) {
      await updatePensionApplicationFormStep({
        step: PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS,
      });
    }
  };

  const tabs = [
    {
      title: PROFILE_SECTION_TABS_NAME.PERSONAL,
      component: (
        <PersonalInformation tabHandler={tabHandler} tabsRef={tabsRef} />
      ),
    },
    {
      title: PROFILE_SECTION_TABS_NAME.CONTACT,
      component: <Contact tabsRef={tabsRef} />,
    },
    {
      title: PROFILE_SECTION_TABS_NAME.SPOUSAL,
      component: <Spousal tabsRef={tabsRef} />,
    },
    {
      title: PROFILE_SECTION_TABS_NAME.BENEFICIARIES,
      component: <Beneficiaries tabsRef={tabsRef} />,
    },
    {
      title: PROFILE_SECTION_TABS_NAME.PREFERENCES,
      component: <Preferences tabHandler={tabHandler} tabsRef={tabsRef} />,
    },
  ];

  const showTitle = () => {
    switch (activeTab) {
      case 0:
        return PROFILE_SECTION_TABS_NAME.PERSONAL;
      case 1:
        return PROFILE_SECTION_TABS_NAME.CONTACT;
      case 2:
        return PROFILE_SECTION_TABS_NAME.SPOUSAL;
      case 3:
        return PROFILE_SECTION_TABS_NAME.BENEFICIARIES;
      case 4:
        return PROFILE_SECTION_TABS_NAME.PREFERENCES;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (
      pathname === ROUTE_PATH.APPLICATION_FORM &&
      applicationFormActiveTab ===
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE
    ) {
      switch (activeTab) {
        case 0:
          dispatch(
            setScreenName(
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PROFILE
            )
          );
          break;
        case 1:
          dispatch(
            setScreenName(
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_CONTACT
            )
          );
          break;
        case 2:
          dispatch(
            setScreenName(
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_SPOUSAL
            )
          );
          break;
        case 3:
          dispatch(
            setScreenName(
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_BENEFICIARIES
            )
          );
          break;
        case 4:
          dispatch(
            setScreenName(
              SCREENS_NAME_MAP_FOR_GLOBAL_CHATBOT.APPLICATION_FORM_PREFERENCES
            )
          );
          break;
        default:
          break;
      }
    }
  }, [activeTab, applicationFormActiveTab]);

  useEffect(() => {
    if (
      lastActiveStep &&
      lastActiveStep !== MAPPED_SCREEN_NAME_WITH_STEP[screenName]
    ) {
      switch (lastActiveStep) {
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE:
          tabsRef.current?.setActiveTab(0);
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_CONTACT:
          tabsRef.current?.setActiveTab(1);
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SPOUSAL:
          tabsRef.current?.setActiveTab(2);
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_BENEFICIARIES:
          tabsRef.current?.setActiveTab(3);
          break;
        case PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PREFERENCES:
          tabsRef.current?.setActiveTab(4);
          break;
        default:
          break;
      }
    }
  }, [lastActiveStep]);

  useEffect(() => {
    if (
      applicationFormActiveTab ===
        PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_PROFILE &&
      redirectScreenStep === 2
    ) {
      switch (redirectScreenTab) {
        case PROFILE_SECTION_TABS_NAME_FOR_AI_RESPONSE.PERSONAL:
          tabsRef.current?.setActiveTab(0);
          dispatch(setRedirectScreenStep(null));
          dispatch(setRedirectScreenTab(null));
          break;
        case PROFILE_SECTION_TABS_NAME_FOR_AI_RESPONSE.CONTACT:
          tabsRef.current?.setActiveTab(1);
          dispatch(setRedirectScreenStep(null));
          dispatch(setRedirectScreenTab(null));
          break;
        case PROFILE_SECTION_TABS_NAME_FOR_AI_RESPONSE.SPOUSAL:
          tabsRef.current?.setActiveTab(2);
          dispatch(setRedirectScreenStep(null));
          dispatch(setRedirectScreenTab(null));
          break;
        case PROFILE_SECTION_TABS_NAME_FOR_AI_RESPONSE.BENEFICIARIES:
          tabsRef.current?.setActiveTab(3);
          dispatch(setRedirectScreenStep(null));
          dispatch(setRedirectScreenTab(null));
          break;
        case PROFILE_SECTION_TABS_NAME_FOR_AI_RESPONSE.PREFERENCES:
          tabsRef.current?.setActiveTab(4);
          dispatch(setRedirectScreenStep(null));
          dispatch(setRedirectScreenTab(null));
          break;
        default:
          tabsRef.current?.setActiveTab(0);
          dispatch(setRedirectScreenStep(null));
          dispatch(setRedirectScreenTab(null));
          break;
      }
    }
  }, [redirectScreenStep, redirectScreenTab, applicationFormActiveTab]);

  return (
    <>
      <div className='relative  h-full flex flex-col justify-between'>
        <div className='pb-2 sticky top-0  bg-light-gray z-1'>
          <h2 className='text-xxl font-ibm font-semibold text-piddy-dark-blue'>
            {showTitle()}
          </h2>
        </div>
        <div className='flex-1 relative'>
          <div className='px-4 pt-4 mb-2 rounded-t-2xl bg-white shadow-double sticky top-[50px] z-1'>
            <ProfileHeaderCard
              firstName={userInfo?.data?.first_name}
              lastName={userInfo?.data?.last_name}
              email={userInfo?.data?.email}
              contactNo={userInfo?.data?.contact_information?.contact_no}
              state={userInfo?.data?.contact_information?.state}
              country={userInfo?.data?.contact_information?.country}
              profilePhotoUrl={userInfo?.data?.profile}
            />
            <div className='flex gap-10'>
              <button
                className={`text-xxs pb-1 border-b-4  font-medium duration-300 ${
                  activeTab === 0
                    ? 'border-black text-black'
                    : 'border-transparent text-neutral-gray'
                }`}
                onClick={() => tabsRef.current?.setActiveTab(0)}
              >
                Profile
              </button>
              <button
                className={`text-xxs pb-1 border-b-4  font-medium duration-300 ${
                  activeTab === 1
                    ? 'border-black text-black'
                    : 'border-transparent text-neutral-gray'
                }`}
                onClick={() => tabsRef.current?.setActiveTab(1)}
              >
                Contact
              </button>
              {userInfo?.data?.marital_status !==
                MARITAL_STATUS_OPTIONS.SINGLE && (
                <button
                  className={`text-xxs pb-1 border-b-4  font-medium duration-300 ${
                    activeTab === 2
                      ? 'border-black text-black'
                      : 'border-transparent text-neutral-gray'
                  }`}
                  onClick={() => tabsRef.current?.setActiveTab(2)}
                >
                  Spousal
                </button>
              )}
              <button
                className={`text-xxs pb-1 border-b-4  font-medium duration-300 ${
                  activeTab === 3
                    ? 'border-black text-black'
                    : 'border-transparent text-neutral-gray'
                }`}
                onClick={() => tabsRef.current?.setActiveTab(3)}
              >
                Beneficiaries
              </button>
              <button
                className={`text-xxs pb-1 border-b-4  font-medium duration-300 ${
                  activeTab === 4
                    ? 'border-black text-black'
                    : 'border-transparent text-neutral-gray'
                }`}
                onClick={() => tabsRef.current?.setActiveTab(4)}
              >
                Preferences
              </button>
            </div>
          </div>
          <div className='bg-white p-6 rounded-b-lg z-0 relative'>
            <Tabs
              theme={customTabTheme}
              ref={tabsRef}
              onActiveTabChange={(tab) => setActiveTab(tab)}
            >
              {tabs?.map((tab, index) => {
                return (
                  <Tabs.Item key={index} active title={tab.title}>
                    {tab.component}
                  </Tabs.Item>
                );
              })}
            </Tabs>
          </div>
        </div>
      </div>
      {/* bottom buttons */}
      <div className='absolute p-4  bottom-0 left-0 w-full flex  items-center justify-between '>
        <FormSubmit
          buttonText={'Previous'}
          className={
            'secondary-btn-white text-black border-black hover:text-black'
          }
          onClick={() => {
            if (activeTab === 0) {
              tabHandler(
                PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_SERVICE_RECORD
              );
            } else if (activeTab > 0 && activeTab <= 4) {
              if (
                userInfo?.data?.marital_status === MARITAL_STATUS_OPTIONS.SINGLE &&
                activeTab === 3
              ) {
                tabsRef.current?.setActiveTab(1);
              } else {
                tabsRef.current?.setActiveTab(activeTab - 1);
              }
            }
          }}
        />
        <FormSubmit
          buttonText={'Next'}
          className={'primary-btn bg-black hover:bg-black hover:text-white'}
          onClick={() => {
            changeScreen();
            if (activeTab === 4) {
              tabHandler(
                PENSION_APPLICATION_FORM_STEPS.APPLICATION_FORM_ELECTIONS
              );
            } else if (activeTab >= 0 && activeTab < 4) {
              if (
                userInfo?.data?.marital_status === MARITAL_STATUS_OPTIONS.SINGLE &&
                activeTab === 1
              ) {
                tabsRef.current?.setActiveTab(3);
              } else {
                tabsRef.current?.setActiveTab(activeTab + 1);
              }
            }
          }}
        />
      </div>
    </>
  );
};

ProfileSetup.propTypes = {
  tabHandler: PropTypes.func.isRequired,
  applicationFormActiveTab: PropTypes.number.isRequired,
};

export default ProfileSetup;
